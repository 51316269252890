angular.module('services')
    .factory('Provincia', ['$resource', function ($resource) {
        return $resource('/api/provincia/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              },
              byIdPais: {
                method: 'GET',
                url: 'provincia/IdPais/:id_pais',
                isArray:true
              }
            }
        );
    }]);
