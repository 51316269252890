angular.module('controllers')
    .controller('VehiculoController', ['Vehiculo', 'Tercero', 'Marca', 'Modelo', 'Version', 'Equipamiento', 'Color', 'Ubicacion', 'Transaccion', 'PagoProveedor', 'Comisionista', 'Transportista', 'DTOptionsBuilder', 'ConfirmDelete', 'DTColumnBuilder', '$controller', '$compile', '$scope', '$filter', 'selectRows', 'Notify', 'PermPermissionStore', 'Lote', '$localStorage',
        function (Vehiculo, Tercero, Marca, Modelo, Version, Equipamiento, Color, Ubicacion, Transaccion, PagoProveedor, Comisionista, Transportista, DTOB, CD, DTColumnBuilder, $controller, $compile, $scope, $filter, selectRows, Notify,  PPS, Lote, $ls) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.Model = Vehiculo;
            self.vehiculos = {};
            self.SR = selectRows;
            self.selectAll = false;
            self.dtInstance = {};
            self.filters = {};
            initFilters();
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/vehiculo/datatable',
                    type: 'GET',
                    data : function(d){
                        return $.extend({}, d, self.filters);
                    }
                    
                })
                .withOption('search', {'search': self.filters.input_search})
                .withOption('order', [0, 'desc'])
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('responsive', true)
                .withOption("pageLength", 50)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive table_vehicle"Blfrtip>')
                .withBootstrap()
                .withButtons([{extend: 'colvis', text: 'Visibles', 'columns': [8,10,11,12,13,14,17,18,19]}])
                .withOption('fnDrawCallback', function(settings) {
                    self.selected = {};
                    $.each(settings.json.data, function(k, v){
                        self.selected[v.id] = false;
                    })
                });
            self.dtColumns = [
                DTColumnBuilder.newColumn('id').withOption('name', 'vehiculos.id').notVisible(),
                DTColumnBuilder.newColumn('null').withTitle('<input ng-model="Vehiculo.selectAll" ng-click="Vehiculo.SR.toggleAll(Vehiculo.selectAll, Vehiculo.selected)" type="checkbox">').notSortable().withOption('searchable', false).renderWith(function(data, type, full, meta){
                        return '<input ng-model="Vehiculo.selected[' + full.id + ']" ng-click="Vehiculo.SR.toggleOne(Vehiculo.selected)" type="checkbox">';
                }),
                DTColumnBuilder.newColumn('bastidor').withTitle('Bastidor').withOption('name', 'vehiculos.bastidor'),
                DTColumnBuilder.newColumn('matricula').withTitle('Matrícula').withOption('name', 'vehiculos.matricula'),
                DTColumnBuilder.newColumn('marca_nombre').withTitle('Marca').withOption('name', 'marcas.nombre').withOption('searchable', false),
                DTColumnBuilder.newColumn('modelo_nombre').withTitle('Modelo').withOption('name', 'modelos.nombre').withOption('searchable', false),
                DTColumnBuilder.newColumn('version_nombre').withTitle('Version').withOption('name', 'versiones.nombre').withOption('searchable', false),
                DTColumnBuilder.newColumn('color_nombre').withTitle('Color').withOption('name', 'colores.nombre'),
                DTColumnBuilder.newColumn('precio_compra').withTitle('Precio Compra').withOption('name', 'vehiculos.precio_compra').notVisible(),
                DTColumnBuilder.newColumn('precio_venta').withTitle('Precio Venta').withOption('name', 'vehiculos.precio_venta'),
                DTColumnBuilder.newColumn('danyo').withTitle('Daño').withOption('name', 'vehiculos.danyo').notVisible(),
                DTColumnBuilder.newColumn('numero_factura').withTitle('Núm. Fact.').withOption('name', 'factura_transaccion.numero').notVisible(),
                DTColumnBuilder.newColumn('fecha_factura').withTitle('Fecha Fact.').withOption('name', 'factura_transaccion.fecha').notVisible(),
                DTColumnBuilder.newColumn('numero_proforma').withTitle('Núm. Prof.').withOption('name', 'proformas.id').notVisible(),
                DTColumnBuilder.newColumn('fecha_proforma').withTitle('Fecha Prof.').withOption('name', 'proformas.fecha').notVisible(),
                DTColumnBuilder.newColumn('proveedor_nombre').withTitle('Proveedor').withOption('name', 'terceros.nombre').withOption('searchable', false),
                DTColumnBuilder.newColumn('cliente_nombre').withTitle('Cliente').withOption('name', 'cliente.nombre').withOption('searchable', false),
                DTColumnBuilder.newColumn('estado').withTitle('Estado').renderWith(function(data, type, full, meta){
                    return self.showEstado(full);
                }).withOption('name','vehiculos.estado').notVisible(),
                DTColumnBuilder.newColumn('ubicacion').withTitle('Ubicacion').renderWith(function(data, type, full, meta){
                    return (data == null) ? '': data;
                }).withOption('name','ubicaciones.nombre').notVisible(),
                DTColumnBuilder.newColumn('ref_proveedor').withTitle('Ref_Prov.').withOption('name', 'vehiculos.ref_proveedor').notVisible(),
                DTColumnBuilder.newColumn('anulado').withTitle('Anul.').withOption('name', 'vehiculos.anulado').withOption('width', '80px').renderWith(function(data, type, full, meta){
                        return '<span class="fa fa-circle '+( full.anulado == 0 ? 'text-success' : 'text-danger')+'"></span>';
                }),
                DTColumnBuilder.newColumn(null).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '100px').withOption('visible', PPS.hasPermissionDefinition('admin') ? true : false).withOption('searchable', false).withClass('all')
                    .renderWith(function(data, type, full, meta) {
                    self.vehiculos[data.id] = data;
                    return '<div class="buttons" style="width:85px"><button type="button" ng-click="Vehiculo.anulado('+data.id+')" class="mb-sm btn btn-xs btn-default"><em class="fa fa-exchange"></em></button> <button type="button" ng-click="Vehiculo.editar('+data.id+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button> <button type="button" ng-click="Vehiculo.delete('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button></div>';
                }),
            ]

            activate();

            function activate(){
                self.marcas = Marca.query();
                self.proveedores = Tercero.getByTipo({tipo: 'proveedor'});
                self.clientes = Tercero.getByTipo({tipo:'cliente'});
                self.SiNo = [{id: 'si', nombre: 'Si'}, {id: 'no', nombre: 'No'}];
                self.sel10 = [{id: 1, nombre: 'Si'}, {id: 0, nombre: 'No'}];
                self.colores = [];
                self.ubicaciones = [];
                self.equipamientos = [];
                self.comisionistas = [];
                self.transportistas = [];
                self.lotes = [];
                self.dateOptions = {
                    startingDay: 1,
                }
            }

            self.dtInstanceCallback = function(dtInstance){
                self.dtInstance = dtInstance; 
            }

            self.search = function(){
                self.dtInstance.reloadData();
            }

            self.no_facturados = function(){
                Vehiculo.no_facturados(self.filters, function(res){
                    self.veh_no_fact = res;
                    self.modal('partials/vehiculo/no_facturado_pdf', 'ModalPdfCtrl', self, 'lg');
                })
            }
            self.latest_index = 0;
            self.numItems = function(index){
                c = index-(self.latest_index==0 ? -1 : self.latest_index);
                if(c<0) c = self.latest_index+c+index;
                self.latest_index = index;
                return c>1 ? c+' registros' : c+' registro';
            }

            self.getTotal = function(index){
                var total = parseFloat(self.veh_no_fact[index].precio_venta);
                for(var i = (index-1); i>=0; i--){
                    if(self.veh_no_fact[i].cliente_id == self.veh_no_fact[index].cliente_id)
                        total+=parseFloat(self.veh_no_fact[i].precio_venta);
                    else break;
                }
                return total;
            }

            self.estados = [{value: 0, text: 'Usado'}, {value: 1, text: 'Nuevo'}];
            self.particular = [{value: 0, text: 'No'}, {value: 1, text: 'Si'}];

            self.showEstado = function(vehiculo) {
                var selected = $filter('filter')(self.estados, {value: vehiculo.estado});
                return selected.length ? selected[0].text : '';
            }

            self.showParticular = function(vehiculo) {
                var selected = $filter('filter')(self.particular, {value: vehiculo.particular});
                return selected.length ? selected[0].text : '';
            }

            self.showEquipamientos = function(equipamientos){
                if(angular.isDefined(equipamientos)){
                    var text = [];
                    for (var i = 0; i < equipamientos.length; i++) {
                      text.push(equipamientos[i].nombre);
                    }
                    return text.toString();
                }
            }

            self.showComisionista = function(comisionista){
                if(comisionista!=null){
                    var selected = $filter('filter')(self.comisionistas, {id: comisionista.id});
                    if(selected.length) self.vehiculo.comisionistado = selected[0];
                    return selected.length ? selected[0].nombre : '';
                }
            }

            self.chargeModelos = function(marcaId, version){
                if(angular.isDefined(marcaId) && marcaId!='') return Modelo.byIdMarca({id_marca: marcaId}, function(res){
                    if(angular.isDefined(version)) {
                        version.id = undefined;
                        self.versiones = [];
                        if(angular.isDefined(version.modelo)) version.modelo.id = undefined;
                    }
                    self.modelos = res;
                });
            }

            self.chargeVersiones = function(modeloId, version){
                if(angular.isDefined(modeloId) && modeloId!='') return Version.byIdModelo({id_modelo: modeloId}, function(res){
                    if(angular.isDefined(version)) version.id = undefined;
                    self.versiones = res;
                });
            }

            self.selectedModelo = function(model){
                if(!angular.isDefined(self.vehiculo.version.modelo)) self.vehiculo.version = {modelo: {id:model}};
                else self.vehiculo.version.modelo.id= model;
            }

            self.selectedVersion = function(model){
                self.vehiculo.version.id = model;
            }

            self.reload = function() {
                self.dtInstance.reloadData();
            }

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar este vehículo?').then(function(){
                Vehiculo.remove({Id: index}, function(res){
                    self.dtInstance.reloadData();
                });
              });
            }

            self.eliminarSeleccionados = function(){
                var id = self.SR.getSelected(self.selected);
                if(id.length){
                    CD.confirmar('¿Seguro quieres eliminar todos los vehículos seleccionados?').then(function(){
                        Vehiculo.eliminarSeleccionados({ids: id}, function(res){
                            self.dtInstance.reloadData();
                        }, function(){
                            self.dtInstance.reloadData();
                        });
                    });
                }
            }

            self.asignarTransaccion = function(){ 
                var id = [];
                var error = false;
                $.each(self.selected, function(k, v){
                    if(v===true) {
                        if(self.vehiculos[k].anulado==1 || self.vehiculos[k].transaccion) {
                            if(self.vehiculos[k].anulado==1) Notify.alert('El vehículo está anulado', {status: 'danger', timeout: 5000});
                            if(self.vehiculos[k].transaccion) Notify.alert('El vehículo ya está en transaccion', {status: 'danger', timeout: 5000});
                            id = [];
                            return false;
                        }
                        if((self.vehiculos[k].bastidor==null || self.vehiculos[k].matricula==null) && self.vehiculos[k].precio_compra == null) {
                            id = [];
                            Notify.alert('Bastidor o matríula y precio de venta y compra deben estar rellenados', {status: 'danger', timeout: 5000});
                            return false;
                        }

                        id.push(k);
                    }
                })
                if(id.length) { 
                    self.idSelected = id 
                    self.modal('partials/vehiculo/asignarTransaccion', 'ModalVehiculoCtrl', self); 
                }
            }

            self.desasignarTransaccion = function(){
                var id = [];
                $.each(self.selected, function(k, v){
                    if(v===true){
                        if(self.vehiculos[k].transaccion && !self.vehiculos[k].proforma) id.push(k);
                        else{
                            Notify.alert('Vehículo en proforma o no en transacción', {status: 'danger', timeout: 5000});
                            id = [];
                            return false;
                        }
                    }
                });
                if(id.length){
                    Transaccion.eliminarSeleccionados({'ids': id}, function(res){
                        self.dtInstance.reloadData();
                    }, function(){self.dtInstance.reloadData();});
                }
            }

            self.pagoVehiculo = function(){
                var id = [];
                $.each(self.selected, function(k, v){
                    if(v===true){
                        if(self.vehiculos[k].pagado || self.vehiculos[k].anulado){
                            if(self.vehiculos[k].anulado)
                                Notify.alert('El vehículo está anulado', {status: 'danger', timeout: 5000});
                            else if(self.vehiculos[k].pagado)
                                Notify.alert('Vehículo ya pagado', {status: 'danger', timeout: 5000});
                            id = [];
                            return false;
                        }
                        id.push(k);
                    }
                });
                if(id.length){
                    self.idSelected = id;
                    self.modal('partials/vehiculo/pagoProveedores', 'ModalVehiculoCtrl', self);
                }
            }

            self.asignarComisionista = function(){
                var id = [];
                $.each(self.selected, function(k, v){
                    if(v===true){
                        if(self.vehiculos[k].comisionado || self.vehiculos[k].anulado){
                            if(self.vehiculos[k].anulado)
                                Notify.alert('El vehículo está anulado', {status: 'danger', timeout: 5000});
                            else if(self.vehiculos[k].comisionado)
                                Notify.alert('El vehículo ya tiene un comisionista', {status: 'danger', timeout: 5000});
                            id = [];
                            return false;
                        }
                        id.push(k);
                    }
                });
                if(id.length){
                    self.idSelected = id;
                    if(!self.comisionistas.length) self.comisionistas = Comisionista.query(); 
                    self.modal('partials/comisionista/asignarVehiculo', 'ModalVehiculoCtrl', self);
                }
            }

            self.asignarTransportista = function(){
                var id = [];
                $.each(self.selected, function(k, v){
                    if(v===true){
                        if(self.vehiculos[k].anulado || self.vehiculos[k].transporte || self.vehiculos[k].cmr){
                            if(self.vehiculos[k].anulado)
                                Notify.alert('El vehículo está anulado', {status: 'danger', timeout: 5000});
                            else if(self.vehiculos[k].transporte && !self.vehiculos[k].cmr)
                                Notify.alert('El vehículo ya esta asignado a transportista', {status: 'danger', timeout: 5000});
                            else if(self.vehiculos[k].cmr)
                                Notify.alert('El vehículo ya ha sido transportado', {status: 'danger', timeout: 5000});
                            id = [];
                            return false;    
                        }
                        id.push(k);
                    }
                });
                if(id.length){
                    self.idSelected = id;
                    if(!self.transportistas.length) self.transportistas = Transportista.query();
                    self.modal('partials/transportista/asignarVehiculo', 'ModalVehiculoCtrl', self); 
                }
            }

            self.anyadirAlLote = function  (){
                var id = [];
                var estaEnLote = false;
                $.each(self.selected, function(k, v){
                    if (v===true) {
                        if(self.vehiculos[k].enLote) estaEnLote = true;
                        id.push(k);
                    }
                });
                if(estaEnLote){
                    Notify.alert('Vehículo en lote', {status: 'danger', timeout: 5000});
                }else if (id.length) {
                    self.idSelected = id;
                    if (!self.lotes.length) self.lotes = Lote.query();
                    self.modal('partials/lote/anyadirAlLote', 'ModalVehiculoCtrl', self);
                }
            }

            self.opened1 = false;
            self.opened2 = false;
            self.open = function(elementOpened) {
                self[elementOpened] = !self[elementOpened];
            };

            self.add = function(){
                self.vehiculo = {isNew: true, version:{}};
                self.openModal();
            }
            self.openModal = function() {
                if(!self.equipamientos.length) self.equipamientos = Equipamiento.query();
                if(!self.colores.length) self.colores = Color.query();
                if(!self.ubicaciones.length) self.ubicaciones = Ubicacion.query();
                if(!self.comisionistas.length) self.comisionistas = Comisionista.query(); 
                self.modal('partials/vehiculo/createOrUpdate', 'ModalVehiculoCtrl', self);
            }
            self.editar = function(index){
                Vehiculo.get({Id: index}, function(res){
                    self.vehiculo = res;
                    if(self.vehiculo.fecha_matriculacion!=null) self.vehiculo.fecha_matriculacion = new Date(self.vehiculo.fecha_matriculacion);
                    if(self.vehiculo.ini_garantia!=null) self.vehiculo.ini_garantia = new Date(self.vehiculo.ini_garantia); 
                    if(self.vehiculo.fin_garantia!=null) self.vehiculo.fin_garantia = new Date(self.vehiculo.fin_garantia); 
                    self.vehiculo.equipamiento = [];
                    angular.forEach(self.vehiculo.equipamientos, function(value, key){
                        self.vehiculo.equipamiento.push(value.id);
                    });
                    self.openModal();
                });
                
            }

            self.anulado = function(index){
                if(!self.vehiculos[index].transaccion && !self.vehiculos[index].pagado){ 
                    Vehiculo.cambioAnulado({Id:index}, {}, function(res){
                        self.dtInstance.reloadData();
                    });
                }
                else Notify.alert('¡No puedes anular vehículo, ya que, está en una transacción o pagado!', {status: 'danger', timeout: 5000}); 
            }

            self.saveFilters = function(){
                self.filters.input_search = $('.table_vehicle input[type="search"]').val();
                $ls.filters = self.filters;
            }

            function initFilters(){
                $.extend(self.filters, $ls.filters);
                if(angular.isDefined(self.filters.to))
                    self.filters.to = new Date(self.filters.to);
                if(angular.isDefined(self.filters.from))
                    self.filters.from = new Date(self.filters.from);
                if(angular.isDefined(self.filters.marca))
                    self.modelos = Modelo.byIdMarca({id_marca: self.filters.marca});
                if(angular.isDefined(self.filters.modelo))
                    self.versiones = Version.byIdModelo({id_modelo: self.filters.modelo});
            }
        }
    ])

angular.module('controllers').controller('ModalVehiculoCtrl', ['$uibModalInstance', 'resolve', 'Pais', 'ConfirmDelete', '$q', 'Transaccion', 'PagoProveedor', 'ComisionistaVehiculo', 'TransportistaVehiculo', '$controller', 'LoteVehiculo', function($MI, resolve, Pais, CD, $q, Transaccion, PagoProveedor, ComisionistaVehiculo, TransportistaVehiculo, $controller, LoteVehiculo) { 
    angular.extend(this, $controller('ModalSaveModelCtrl', {$uibModalInstance: $MI, resolve:resolve}));
    var self = this;

    self.opened = false;
    self.opened1 = false;
    self.opened2 = false;
    self.opened3 = false;
    self.opened4 = false;
    self.open = function($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();
        self[elementOpened] = !self[elementOpened];
    }

    self.saveVehiculo = function(data, form){
        data.num_vehiculos = self.num_vehiculos;
        var d = $q.defer();
        if(data.num_vehiculos>=10){
            CD.confirmar('¿Seguro quieres insertar mas de 10 Vehículos?').then(function(){
                d.resolve(self.saveModel(data, form, 'vehiculo'));
            }, function(){
                d.resolve('');
            });
            return d.promise;
        }
        else return self.saveModel(data, form, 'vehiculo');
    }

    self.chargeOrigenes = function(){
        if(!angular.isDefined(self.resolve.origenes))self.resolve.origenes = Pais.query();
    }

    self.asignarTransaccion = function(data, form) {
        self.transaccion = {isNew: true};
        var check = true;
        $.each(self.resolve.idSelected, function(k, v){
            if(parseFloat(data.precio_venta)<parseFloat(self.resolve.vehiculos[v].precio_compra)){
                check = false;
                return false;
            }
        });

        if(check)
            return sendAsignarTransaccion(data, form);
        else{
            CD.confirmar('Precio de venta es inferior al de compra<br>¿quieres continuar?').then(function(){
                sendAsignarTransaccion(data, form);
            });
            return "string";
        }
        
    }

    function sendAsignarTransaccion(data, form){
        data.ids = self.resolve.idSelected;
        return self.resolve.base.save(Transaccion, data, self.transaccion, form).then(function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData();
        })   
    }

    self.pagoProveedor = function(data, form){
        self.pago_vehiculo = {isNew: true};
        data.ids = self.resolve.idSelected;
        return self.resolve.base.save(PagoProveedor, data, self.pago_vehiculo, form).then(function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData();
        });
    }

    self.asignarComisionista = function(data, form){
        self.comisionista_vehiculo = {isNew: true};
        data.ids = self.resolve.idSelected;
        return self.resolve.base.save(ComisionistaVehiculo, data, self.comisionista_vehiculo, form).then(function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData();
        })
    }

    self.asignarTransportista = function(data, form){
        self.transportista_vehiculo = {isNew: true};
        data.ids = self.resolve.idSelected;
        return self.resolve.base.save(TransportistaVehiculo, data, self.transportista_vehiculo, form).then(function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData();
        })
    }

    self.anyadirAlLote = function(data, form){
        self.lote_vehiculo = {isNew: true};
        data.ids = self.resolve.idSelected;
        return self.resolve.base.save(LoteVehiculo, data, self.lote_vehiculo, form).then(function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData();
        })
    }

}]);