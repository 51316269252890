/**=========================================================
 * Module: helpers.js
 * Provides helper functions for routes definition
 =========================================================*/


(function() {
    'use strict';

    angular
        .module('app.routes')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', '$locationProvider', '$urlRouterProvider', 'RouteHelpersProvider'];
    function routesConfig($stateProvider, $locationProvider, $urlRouterProvider, helper){
        
        // Set the following to true to enable the HTML5 Mode
        // You may have to set <base> tag in index and a routing configuration in your server
        $locationProvider.html5Mode(true);

        // defaults to dashboard
        $urlRouterProvider.otherwise('/inicio');
        //$urlRouterProvider.otherwise('/app/singleview');

        // 
        // Application Routes
        // -----------------------------------   
        $stateProvider

            .state('login', {
                url: '/user/login',
                title: 'Login',
                templateUrl: helper.basepath('user/login'),
                controller: 'LoginController as login'
            })
            .state('app', {
                abstract: true,
                url: '/',
                templateUrl: helper.basepath('base'),
                resolve: helper.resolveFor('fastclick', 'modernizr', 'icons', 'screenfull', 'animo', 'slimscroll', 'whirl', 'ngDialog', 'datatables', 'parsley')
            })
            .state('app.home',{
                url: 'inicio',
                title: 'Inicio',
                templateUrl: helper.basepath('home'),
            })
            .state('app.log',{
                url: 'log',
                title: 'Logs',
                templateUrl: helper.basepath('log/lista'),
                controller: 'LogController as Log',
            })
            .state('app.empresa',{
                url: 'empresa',
                title: 'Empresa',
                templateUrl: helper.basepath('empresa'),
                controller: 'EmpresaController as Empresa',
                resolve: helper.resolveFor('xeditable', 'ui.select'),
            })
            .state('app.user', {
                abstract: true,
                url: 'user/',
                templateUrl: helper.basepath('user'),
                controller: 'UserController as User',
            })
            .state('app.user.create', {
                url: 'create',
                title: 'Crear Usuario',
                templateUrl: helper.basepath('user/register')
            })
            .state('app.user.update', {
                url: 'update/{id:int}',
                title: 'Modificar Usuario',
                templateUrl: helper.basepath('user/register'),
            })
            .state('app.user.lista',{
                url: 'lista',
                title: 'Listado',
                templateUrl: helper.basepath('user/lista'),
            })
            .state('app.marca', {
                url: 'marca',
                title: 'Marcas',
                templateUrl: helper.basepath('marca/lista'),
                controller: 'MarcaController as Marca',
                resolve: helper.resolveFor('xeditable')
            })
            .state('app.modelo', {
                url: 'modelo',
                title: 'Modelos',
                templateUrl: helper.basepath('modelo/lista'),
                controller: 'ModeloController as Modelo',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.version', {
                url: 'version',
                title: 'Versiones',
                templateUrl: helper.basepath('version/lista'),
                controller: 'VersionController as Version',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.color', {
                url: 'color',
                title: 'Colores',
                templateUrl: helper.basepath('color/lista'),
                controller: 'ColorController as Color',
                resolve: helper.resolveFor('xeditable')
            })
            .state('app.equipamiento', {
                url: 'equipamiento',
                title: 'Equipamientos',
                templateUrl: helper.basepath('equipamiento/lista'),
                controller: 'EquipamientoController as Equipamiento',
                resolve: helper.resolveFor('xeditable')
            })
            .state('app.ubicacion', {
                url: 'ubicacion',
                title: 'Ubicaciones',
                templateUrl: helper.basepath('ubicacion/lista'),
                controller: 'UbicacionController as Ubicacion',
                resolve: helper.resolveFor('xeditable')
            })
            .state('app.pais', {
                url: 'pais',
                title: 'Paises',
                templateUrl: helper.basepath('pais/lista'),
                controller: 'PaisController as Pais',
                resolve: helper.resolveFor('xeditable')
            })
            .state('app.provincia', {
                url: 'provincia',
                title: 'Provincias',
                templateUrl: helper.basepath('provincia/lista'),
                controller: 'ProvinciaController as Provincia',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.poblacion', {
                url: 'poblacion',
                title: 'Poblaciones',
                templateUrl: helper.basepath('poblacion/lista'),
                controller: 'PoblacionController as Poblacion',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.cuentas_bancarias', {
                url: 'cuentas',
                title: 'Cuentas Bancarias',
                templateUrl: helper.basepath('cuenta/lista'),
                controller: 'CuentasBController as Cuenta',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.tercero', {
                url: 'terceros',
                title: 'Terceros',
                templateUrl: helper.basepath('tercero/lista'),
                controller: 'TerceroController as Tercero',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.vehiculo', {
                url: 'vehiculos',
                title: 'Vehículos',
                templateUrl: helper.basepath('vehiculo/lista'),
                controller: 'VehiculoController as Vehiculo',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.transaccion', { 
                url: 'transaccion', 
                title: 'Transacciones', 
                templateUrl: helper.basepath('transaccion/lista'), 
                controller: 'TransaccionController as Transaccion', 
                resolve: helper.resolveFor('xeditable', 'ui.select') 
            }) 
            .state('app.proforma', {
                url: 'proforma',
                title: 'Proformas',
                templateUrl: helper.basepath('proforma/lista'),
                controller: 'ProformaController as Proforma',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.textofactura', {
                url: 'textofactura',
                title: 'Textos de Facturas',
                templateUrl: helper.basepath('textofactura/lista'),
                controller: 'TextoFacturaController as TextoFactura',
                resolve: helper.resolveFor('xeditable')
            })
            .state('app.factura', {
                url: 'factura',
                title: 'Facturas Vehículo',
                templateUrl: helper.basepath('factura/lista'),
                controller: 'FacturaController as Factura',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.facturak', {
                url: 'facturak',
                title: 'Facturas K',
                templateUrl: helper.basepath('facturak/lista'),
                controller: 'FacturaKController as FacturaK',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.pago_proveedor', {
                url: 'pago_proveedor',
                title: 'Vehiculos pagados',
                templateUrl: helper.basepath('pago_proveedor/lista'),
                controller: 'PagoProveedorController as PagoProveedor',
                resolve: helper.resolveFor('xeditable', 'ui.select', 'angularFileUpload', 'filestyle')
            })
            .state('app.documentos_vehiculo', {
                url: 'documentos_vehiculo',
                title: 'Control de Documentos',
                templateUrl: helper.basepath('documento_vehiculo/lista'),
                controller: 'ControlDocumentosController as DocumentoVehiculo',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.transportista', {
                url: 'transportista',
                title: 'Transportistas',
                templateUrl: helper.basepath('transportista/lista'),
                controller: 'TransportistaController as Transportista',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.importar_vehiculo', {
                url: 'importar/vehiculos',
                title: 'Importación Vehículos',
                templateUrl: helper.basepath('importar/vehiculo'),
                controller: 'ImportarController as Importar',
                resolve: helper.resolveFor('angularFileUpload')
            })
            .state('app.actualizar_vehiculo', {
                url: 'actualizar/vehiculo',
                title: 'Actulización Vehículos',
                templateUrl: helper.basepath('importar/actulizar_vehiculo'),
                controller: 'ImportarController as Importar',
                resolve: helper.resolveFor('angularFileUpload')
            })
            .state('app.comisionista',{
                url: 'comisionista/lista',
                title: 'Comisionista',
                templateUrl: helper.basepath('comisionista/lista'),
                controller: 'ComisionistaController as Comisionista',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.comisionista_vehiculo', {
                url: 'comisionista/comisionista_vehiculo',
                title: 'Vehiculos para Comisionista',
                templateUrl: helper.basepath('comisionista/vehiculosAsociados'),
                params: {
                    comisionista: null,
                },
                controller: 'ComisionistaVehiculoController as ComVeh',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.stock', {
                url: 'stock',
                title: 'Stock',
                templateUrl: helper.basepath('stock/lista'),
                controller: 'StockController as Stock',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.informe', {
                url: 'informe',
                title: 'Informe',
                templateUrl: helper.basepath('informe'),
                controller: 'InformeController as Informe',
                resolve: helper.resolveFor ('ui.select')
            })
            .state('app.lote', {
                url: 'lote',
                title: 'Lote',
                templateUrl: helper.basepath('lote'),
                controller: 'LoteController as Lote',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            .state('app.lote_vehiculo', {
                url: 'lote/lote_vehiculo',
                title: 'Vehiculos para Lote',
                templateUrl: helper.basepath('lote/vehiculosAsociados'),
                params: {
                    lote: null,
                },
                controller: 'LoteVehiculoController as LoteVeh',
                resolve: helper.resolveFor('xeditable', 'ui.select')
            })
            ;

    } // routesConfig

})();