angular.module('controllers')
    .controller('UserController', ['AuthInfo', 'User', 'DTOptionsBuilder', 'DTColumnBuilder', 'ConfirmDelete', 'Modal', 'Notify', '$state', '$compile', '$scope',
        function (AuthInfo, User, DTOB, DTColumnBuilder, CD, Modal, Notify, $state, $compile, $scope) {
            var self = this;
            self.user = {};
            self.users = {};
            self.authMsg = '';
            self.dtInstance = {};
            self.init = function(selected){
              self.authMsg = '';
              if($state.is('app.user.create')) self.user = {role:{id:selected}} ;
              else if (angular.equals({}, self.user)) 
                    User.get({Id: $state.params.id}, function(res){
                        self.user = res;
                        self.user.roles[0].id += ''; 
                    });
            }
        
            self.create = function(){
              self.authMsg = '';
              User.save(self.user, function(){
                $state.go('app.user.lista');
              }, function(err){
                self.authMsg = err.data;
              });
            }

            self.update = function(){
              self.user.$update(function(res){
                $state.go('app.user.lista');
              });
            }

            self.submit = function($event){
              $event.preventDefault();
              $state.is('app.user.update') ? self.update() : self.create();
            }

            self.getModify = function(index){
              User.get({Id: index}, function(res){
                self.user = res;
                self.user.roles[0].id += ''; 
                $state.go('app.user.update', {id: res.id});
              });
            }

            self.lista = function(){
              self.dtOptions = DTOB.newOptions()
                  .withOption('ajax', {
                      dataSrc: 'data',
                      url: '/user/datatable',
                      type: 'GET',
                  })
                  .withOption('serverSide', true)
                  .withOption('processing', true)
                  .withDOM('<"table-responsive"lfrtip>')
                  .withBootstrap()
                  .withOption('createdRow', function(row, data, dataIndex) {
                      $compile(angular.element(row).contents())($scope);
                  })
                  .withOption('headerCallback', function(header) {
                      if (!self.headerCompiled) {
                          self.headerCompiled = true;
                          $compile(angular.element(header).contents())($scope);
                      }
                  })

              self.dtColumns = [
                  DTColumnBuilder.newColumn('username').withTitle('Username'),
                  DTColumnBuilder.newColumn('email').withTitle('Email'),
                  DTColumnBuilder.newColumn('role').withTitle('Role').withOption('name', 'roles.name'),
                  DTColumnBuilder.newColumn('created_at').withTitle('Creado').withOption('name', 'users.created_at'),,
                  DTColumnBuilder.newColumn(null).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '100px').withOption('searchable', false)
                      .renderWith(function(data, type, full, meta) {
                        self.users[data.id] = data;
                      return '<button type="button" ng-click="User.getModify('+data.id+')" class="btn btn-xs btn-warning"><em class="fa fa-edit"></em></button><button type="button" ng-click="User.changePassword('+data.id+')" class="btn btn-xs btn-default"><em class="fa fa-key"></em></button><button type="button" ng-click="User.delete('+data.id+')" class="btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button>';
                  }),
              ];
            }

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar este usuario?').then(function(){
                User.remove({Id:index}, function(res){
                    self.dtInstance.reloadData();
                })
              });
            }

            self.changePassword = function(index){
              Modal.open('changePassword', self.users[index], true).result.then(function(result){
                result.id = self.users[index].id;
                User.changePassword(result, function(){
                  Notify.alert('Contraseña cambiada!!', {status: 'success', timeout: 2000});
                });
              })
            }

        }
    ]);