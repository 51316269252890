angular.module('controllers')
    .controller('TerceroController', ['Tercero', 'Pais', 'DTOptionsBuilder', 'ConfirmDelete', 'DTColumnBuilder', '$controller', '$compile', '$scope', 'PermPermissionStore',
        function (Tercero, Pais, DTOB, CD, DTColumnBuilder, $controller, $compile, $scope, PPS) {
            angular.extend(this, $controller('baseControllerXE', {}));
            angular.extend(this, $controller('LocalidaController', {child : this}));
            var self = this;
            self.Model = Tercero;
            self.dtInstance = {};
            self.paises = [];
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/tercero/datatable',
                    type: 'GET',
                    data: function(d){
                        d.tipo = self.searchByTipo;
                    }
                })
                .withOption('order', [1, 'desc'])
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()
            self.dtColumns = [
                DTColumnBuilder.newColumn('id').withTitle('ID').withOption('id', 'terceros.id'),
                DTColumnBuilder.newColumn('nombre').withTitle('Nombre').withOption('name', 'terceros.nombre'),
                DTColumnBuilder.newColumn('nif').withTitle('Nif').withOption('name', 'terceros.nif'),
                DTColumnBuilder.newColumn('tipo').withTitle('Tipo').notSortable().withOption('searchable', false)
                    .renderWith(function(data, type, full, meta){
                        return self.showTipo(full);
                    }),
                DTColumnBuilder.newColumn('pais_nombre').withTitle('Pais').withOption('name', 'pais.nombre'),
                DTColumnBuilder.newColumn('cp').withTitle('CP').withOption('name', 'terceros.cp'),
                DTColumnBuilder.newColumn(null).withOption('visible', PPS.hasPermissionDefinition('admin') ? true : false).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '80px').withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                    return '<button type="button" ng-click="Tercero.editar('+data.id+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button> <button type="button" ng-click="Tercero.delete('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button>';
                }),
            ]

            self.searchTable = function(){
                self.dtInstance.reloadData();
            }
            self.tipo = [{value: 'cliente', text: 'Cliente'}, {value: 'proveedor', text: 'Proveedor'}];
            self.showTipo = function(tercero) {
                var selected = [];
                if(angular.isDefined(tercero) && tercero.cliente == 1) selected.push('Cliente');
                if(angular.isDefined(tercero) && tercero.proveedor == 1) selected.push('Proveedor');
                return selected.length ? selected.join(', ') : '';
            };

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar este tercero?').then(function(){
                Tercero.remove({Id: index}, function(res){
                    self.dtInstance.reloadData();
                });
              });
            }

            self.selectedPoblacion = function(item, model){
                self.tercero.poblacion=item;
            }

            self.add = function(){
                self.tercero = {isNew: true};
                self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
            }

            self.editar = function(index){
                Tercero.get({Id: index}, function(res){
                    self.tercero = res;
                    self.tercero.tipo = [];
                    self.poblaciones = [self.tercero.poblacion];
                    if(self.tercero.cliente==1) self.tercero.tipo.push('cliente');
                    if(self.tercero.proveedor==1) self.tercero.tipo.push('proveedor');
                    self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
                });
            }

        }
    ])
