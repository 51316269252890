angular.module('services')
    .factory('Proforma', ['$resource', function ($resource) {
        return $resource('/api/proforma/:Id', {
            Id: '@id'
            },
            {
                update: { method: 'PUT' },
                no_facturadas: { method: 'GET', url: 'proforma/no_facturadas', isArray: true }
            }
        );
    }]);