angular.module('controllers').controller('ModalSaveModelCtrl', ['$uibModalInstance', 'resolve', function($MI, resolve) {
    var self = this;
    self.resolve = resolve;
    self.saveModel = function(data, form, object){
        return self.resolve.base.save(self.resolve.Model, data, self.resolve[object], form).then(function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData(function(){}, false);
        })
    }

    self.cancel = function(e) {
        e.preventDefault();
        $MI.dismiss('cancel');
    };

}]);