angular.module('controllers')
    .controller('LocalidaController', ['child', 'Provincia', 'Poblacion', function(child, Provincia, Poblacion){
        var self = this;

        child.chargeProvincias = function(paisId, provincia, poblacion){
            if(angular.isDefined(paisId)) 
                Provincia.byIdPais({id_pais: paisId}, function(res){
                    if(angular.isDefined(provincia) && provincia!= null) provincia.id = undefined;
                    if(angular.isDefined(poblacion) && poblacion != null) poblacion.id = undefined;
                    child.provincias = res;
                    child.poblaciones = [];
                });
        }

        child.chargePoblaciones = function(proId, poblacion){
            if(angular.isDefined(proId))  
                Poblacion.byIdProvincia({id_provincia: proId}, function(res){
                    if(angular.isDefined(poblacion) && poblacion!= null) poblacion.id = undefined;
                child.poblaciones = res;
                });
        }

        child.refreshPoblaciones = function(poblacion)
        {
            if(angular.isDefined(poblacion) && poblacion!=='')
                Poblacion.findByName({name: poblacion}, function(res){
                    child.poblaciones = res;
                })
        }

    }]);