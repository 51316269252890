angular.module('controllers')
    .controller('LoteController', [ 'Lote', 'ConfirmDelete', '$controller', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$scope',  'Notify', '$state', 'DownloadFile', 'LoteVehiculo', 'FileSaver', 'Blob',
        function ( Lote, CD, $controller, DTOB, DTColumnBuilder, $compile, $scope,  Notify, $state, DownloadFile, LoteVehiculo, FileSaver, Blob) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.Model = Lote;
            self.dtInstance = {};
            self.lotes = [];
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/lote/datatable',
                    type: 'GET',
                })
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()

            self.dtColumns = [
                DTColumnBuilder.newColumn('id').withTitle('ID').withOption('name', 'lotes.id'),
                DTColumnBuilder.newColumn('nombre').withTitle('Nombre').withOption('name', 'lotes.nombre'),
                DTColumnBuilder.newColumn('fecha_envio').withTitle('Fecha de envío').withOption('name', 'lotes.fecha_envio'),
                DTColumnBuilder.newColumn('estado').withTitle('Estado').withOption('name', 'lotes.estado'),
                DTColumnBuilder.newColumn(null).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '120px').withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                        self.lotes[data['id']] = data;
                    return '<button type="button" ng-click="Lote.editar('+data.id+')" class="mb-sm btn btn-xs btn-warning"><em class="fa fa-edit"></em></button> <button type="button" ng-click="Lote.delete('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button> <button type="button" ng-click="Lote.verVehiculos('+data.id+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button><button type="button" ng-click="Lote.print('+data.id+')" class="mb-sm btn btn-xs btn-default"><em class="fa fa-print"></em></button>';
                }),
            ];

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar este Lote?').then(function(){
                Lote.remove({Id: index}, function(res){
                    self.dtInstance.reloadData();
                });
              });
            }

            self.add = function(){   
                self.lote = {isNew: true};
                self.openModal();
            }

            self.openModal = function(){
                self.modal('partials/lote/createOrUpdate', 'ModalSaveModelCtrl', self);
            }
            self.editar = function(index){
                Lote.get({Id: index}, function(res){
                    self.lote = res;
                    self.openModal();
                });
            }

            self.verVehiculos = function(index){
                $state.go('app.lote_vehiculo',{lote: self.lotes[index]});
            }

            self.print = function(index){
                DownloadFile.download({action: 'lote_vehiculo_excel'}, {id: index}, function (result) {
                    var data = new Blob([result.data], {type: result.type});
                    FileSaver.saveAs(data, result.filename);
                    self.dtInstance.reloadData();
                });
            }

        }
    ]);
