angular.module('services')
    .factory('Vehiculo', ['$resource', function ($resource) {
        return $resource('/api/vehiculo/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              },
              cambioAnulado: {
                method: 'PATCH',
                url: 'vehiculo/cambioAnulado/:Id',
              },
              eliminarSeleccionados: {
                method: 'POST',
                url: 'vehiculo/eliminar/seleccionados',
              },
              no_facturados: { method: 'post', url: 'vehiculo/no_facturados_pdf', isArray: true }
            }
        );
    }]);