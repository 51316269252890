angular.module('controllers')
    .controller('InformeController', ['Informe', '$controller', '$compile', '$scope', '$filter', 'Notify', 'DownloadFile', 'FileSaver', 'Blob',
        function (Informe, $controller, $compile, $scope, $filter, Notify, DownloadFile, FileSaver, Blob) {
            var self = this;
            self.Model = Informe;
            
            activate();
            function activate(){
                self.dateOptions = {
                    startingDay: 1
                }
            }
            self.opened1 = false;
            self.opened2 = false;
            self.open = function(elementOpened) {
                self[elementOpened] = !self[elementOpened];
            };
            self.imprimir = function(){
                if(self.from == undefined || self.to == undefined){
                    Notify.alert('Introduce filtro de fechas obligatorio',{status: 'info', timeout:5000});
                }else{
                    if( (new Date(self.from)).getFullYear() != (new Date(self.to)).getFullYear() )  Notify.alert('Las fechas deben estar en el mismo año',{status: 'info', timeout:5000});
                    else{
                        var dataSend = {from: self.from, to: self.to};
                        DownloadFile.download({action: 'informe_pdf' }, dataSend , function (result) {
                            var data = new Blob([result.data], {type: result.type});
                            FileSaver.saveAs(data, result.filename);
                        });
                    }
                }
            }
        }
    ])
