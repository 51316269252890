angular.module('controllers')
    .controller('ComisionistaVehiculoController', [ 'ComisionistaVehiculo',  'ConfirmDelete', '$controller', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$scope', 'selectRows','Notify', '$state', 'DownloadFile', 'FileSaver', 'Blob',
        function ( ComisionistaVehiculo, CD, $controller, DTOB, DTColumnBuilder, $compile, $scope, selectRows, Notify, $state, DownloadFile, FileSaver, Blob) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.Notify = Notify;
            self.comisionista = $state.params.comisionista;
            self.Model = ComisionistaVehiculo;
            self.SiNo = [{id: 'si', nombre: 'Si'}, {id: 'no', nombre: 'No'}];
            self.dateOptions = {
                startingDay: 1,
            }
            self.opened = false;

            if(self.comisionista===null){
                $state.go('app.comisionista');
            }else{
                self.dtInstance = {};
                self.dtOptions = DTOB.newOptions()
                    .withOption('ajax', {
                        dataSrc: 'data',
                        url: '/comisionista/vehiculosAsociados/'+self.comisionista.ID+'/'+self.comisionista.Tipo+'/datatable',
                        type: 'GET',
                        data: function(d){
                            d.to = angular.isDefined(self.to) ? self.to.toISOString() : '';
                            d.from = angular.isDefined(self.from) ? self.from.toISOString() : '';
                            d.pagado = self.searchPagado;
                        }
                    })
                    .withOption('serverSide', true)
                    .withOption('processing', true)
                    .withOption('createdRow', function(row, data, dataIndex) {
                        $compile(angular.element(row).contents())($scope);
                    })
                    .withOption('headerCallback', function(header) {
                        if (!self.headerCompiled) {
                            self.headerCompiled = true;
                            $compile(angular.element(header).contents())($scope);
                        }
                    })
                    .withDOM('<"table-responsive"lfrtip>')
                    .withBootstrap();

                self.dtColumns = [
                    DTColumnBuilder.newColumn('fecha_pago')
                        .withTitle('Fecha de pago')
                        .withOption('name','comisionista_vehiculo.fecha_pago'),
                    DTColumnBuilder.newColumn('pagado')
                        .withTitle('Pagado')
                        .withOption('searchable',false)
                        .notSortable()
                        .renderWith(function(data, type, full, meta) {
                            return data ? 'Si' : 'No';
                    }),
                    DTColumnBuilder.newColumn('importe')
                        .withTitle('Importe')
                        .withOption('name','comisionista_vehiculo.importe'),
                    DTColumnBuilder.newColumn('matricula')
                        .withTitle('Matricula')
                        .withOption('name','vehiculos.matricula'),
                    DTColumnBuilder.newColumn('bastidor')
                        .withTitle('Bastidor')
                        .withOption('name','vehiculos.bastidor'),
                    DTColumnBuilder.newColumn('version')
                        .withTitle('Version')
                        .withOption('name','versiones.nombre'),
                    DTColumnBuilder.newColumn('modelo')
                        .withTitle('Modelo')
                        .withOption('name','modelos.nombre'),
                    DTColumnBuilder.newColumn('marca')
                        .withTitle('Marca')
                        .withOption('name','marcas.nombre'),
                    DTColumnBuilder.newColumn(null)
                        .withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '100px').withOption('searchable', false)
                        .renderWith(function(data, type, full, meta) {
                        return '<button type="button" ng-click="ComVeh.editVehiculoComisionista('+data['comVehId']+')" class="mb-sm btn btn-xs btn-warning"><em class="fa fa-car"></em></button> <button type="button" ng-click="ComVeh.delete('+data['comVehId']+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button>';
                    }),
                ];
            }
            self.search = function(){
                self.dtInstance.reloadData();
            }

            self.opened1 = false;
            self.opened2 = false;
            self.open = function(elementOpened) {
                self[elementOpened] = !self[elementOpened];
            };

            self.editVehiculoComisionista = function(index){
                ComisionistaVehiculo.get({Id: index}, function(res){
                    self.comisionistaVehiculo = res;
                    if(self.comisionistaVehiculo.fecha_pago!=null) self.comisionistaVehiculo.fecha_pago = new Date(self.comisionistaVehiculo.fecha_pago);
                    self.modal('partials/comisionista/vehiculo', 'ModalSaveModelCtrl', self);
                });
            }

            self.print = function(){
                if( (self.from !== undefined && self.to !== undefined) || (self.searchPagado !== undefined) ){
                    DownloadFile.download({action: 'comisionista_pdf'}, {from: self.from, to: self.to, pagado: self.searchPagado, id: self.comisionista.ID, tipo: self.comisionista.Tipo}, function (result) {
                        var data = new Blob([result.data], {type: result.type});
                        FileSaver.saveAs(data, result.filename);
                    });
                }else Notify.alert('Introduce algún filtro',{status: 'info', timeout:5000});
            }

            self.delete = function(index){
                CD.confirmar('¿Seguro quieres eliminar este vehiculo?').then(function(){
                    ComisionistaVehiculo.remove({Id: index}, function(res){
                        self.dtInstance.reloadData();
                    });
                });
            }
        }
]);