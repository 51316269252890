angular.module('controllers')
    .controller('MainController', ['$location', 'AuthInfo', '$state', '$pusher', 'PermPermissionStore', function ($location, AuthInfo, $state, $pusher, PPS) {

        var self = this;

        self.authInfo = AuthInfo;
        
        self.userBlockVisible = true;

        self.toggleUserBlock = function(){
            self.userBlockVisible = !self.userBlockVisible;
        }
        self.open = false;
        AuthInfo.getCurrentUser().$promise.then(function(res){
            if(PPS.hasPermissionDefinition('admin')){
                self.open = false;
                var client = new Pusher('e2b5c3abf33af2ea861f', {
                    cluster: 'eu',
                    encrypted: true
                });
                var pusher = $pusher(client);
                var channel = pusher.subscribe('stockCreated');
                self.notifications = [];
                channel.bind('App\\Events\\ReservaCreated', function(data){
                    self.notifications.push(data);
                });
            }
        })

        self.deleteNoti = function(index){
            self.notifications.splice(index, 1);
        }

        self.logout = function(){
            AuthInfo.deleteCurrentUser();
        };

    }]);