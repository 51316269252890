angular.module('services')
    .factory('Modelo', ['$resource', function ($resource) {
        return $resource('/api/modelo/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              },
              byIdMarca: {
                method: 'GET',
                url: 'modelo/IdMarca/:id_marca',
                isArray:true
              }
            }
        );
    }]);