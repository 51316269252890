angular.module('controllers')
    .controller('CuentasBController', ['Cuenta', 'Pais', 'baseModelXE', 'DTOptionsBuilder', 'DTColumnDefBuilder', '$controller', 'ConfirmDelete',
        function (Cuenta, Pais, base, DTOB, DTCB, $controller, CD) {
            angular.extend(this, $controller('baseControllerXE', {}));
            angular.extend(this, $controller('LocalidaController', {child : this}));
            var self = this;
            self.dtColumnDefs = [
                DTCB.newColumnDef(-1).notSortable(),
                DTCB.newColumnDef(-2).notSortable(),
                DTCB.newColumnDef(0).notVisible(),
                DTCB.newColumnDef(1).notVisible(),
                DTCB.newColumnDef(-4).notVisible(),
                DTCB.newColumnDef(-5).notVisible(),
            ];
            
            self.dtOptions = DTOB.newOptions()
                .withDOM('<"table-responsive"Blfrtip>')
                .withBootstrap()
                .withButtons([{extend: 'colvis', text: 'Visibles', 'columns': [7,8]}]);
            self.dtInstance = {};
            self.searchTable = function(){
                self.dtInstance.DataTable.columns(1).search(self.searchByEstado).draw();
            }

            self.cuentas = Cuenta.query();
            self.paises = [];

            self.initPaises = function(){
                self.paises.length ? null : Pais.query(function(res){self.paises = res});
            }

            self.add = function(){   
                if(self.inserted = base.add()) 
                    self.cuentas.push(self.inserted);
            }

            self.cancel = function(index){
                var cancel = base.cancel(self.cuentas[index]);
                if(cancel) self.cuentas.splice(index, 1);
            }

            self.save = function(data, index, form){
                return base.save(Cuenta, data, self.cuentas[index], form).then(function(res){
                    self.cuentas[index] = res.cuenta;
                });
            }

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar esta cuenta?').then(function(){
                Cuenta.remove({Id: self.cuentas[index].id}, function(res){
                    self.cuentas.splice(index, 1);
                });
              });
            }

            self.estado = function(index){
                Cuenta.changeStatus({Id:self.cuentas[index].id}, {}, function(res){
                    self.cuentas[index].cancelada = res.cancelada;
                    self.dtInstance.rerender();
                })
            }

            self.selectedProvincia = function(index, model){
                if(self.cuentas[index].provincia == null) self.cuentas[index].provincia = {id:model};
                else self.cuentas[index].provincia.id = model;
            }

            self.selectedPoblacion = function(index, model){
                if(self.cuentas[index].poblacion == null) self.cuentas[index].poblacion = {id:model};
                else self.cuentas[index].poblacion.id = model;
            }

            self.isEnabled = function (index) {
                return (self.cuentas[index].facturas_k_count == 0 && self.cuentas[index].proformas_count == 0);
            };
        }
    ])