angular.module('controllers')
    .controller('LogController', ['DTOptionsBuilder', 'DTColumnBuilder',
        function (DTOB, DTColumnBuilder) {
            var self = this;
            self.dtInstance = {};
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/log/datatable',
                    type: 'GET',
                })
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()

            self.dtColumns = [
                DTColumnBuilder.newColumn('id_user').withTitle('UID').withOption('name', 'activity_log.causer_id'),
                DTColumnBuilder.newColumn('usuario').withTitle('Usuario').withOption('name', 'users.username'),
                DTColumnBuilder.newColumn('descripcion').withTitle('Descripción').withOption('name', 'activity_log.description'),
                DTColumnBuilder.newColumn('subject_id').withTitle('TID').withOption('name', 'activity_log.subject_id'),
                DTColumnBuilder.newColumn('subject_type').withTitle('Tema').withOption('name', 'activity_log.subject_type'),
                DTColumnBuilder.newColumn('propiedades').withTitle('Propiedades').notSortable().withOption('searchable', false),
                DTColumnBuilder.newColumn('tiempo').withTitle('Tiempo').withOption('name', 'activity_log.created_at'),
            ];
        }
    ]);