angular.module('services')
    .factory('Ubicacion', ['$resource', function ($resource) {
        return $resource('/api/ubicacion/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              }
            }
        );
    }]);
