angular.module('controllers')
    .controller('FacturaController', ['Factura', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$scope', 'selectRows', 'ConfirmDelete', 'TextoFactura', '$controller', 'DownloadFile', 'Notify', 'PermPermissionStore', 'FileSaver', 'Blob',
        function (Factura, DTOB, DTColumnBuilder, $compile, $scope, selectRows, CD, TextoFactura, $controller, DownloadFile, Notify, PPS, FileSaver, Blob) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.SR = selectRows;
            self.selected = {};
            self.selectAll = false;
            self.dtInstance = {};
            self.textoFacturas = [];
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/factura/datatable',
                    type: 'GET',
                    data: function(d){
                        d.to = angular.isDefined(self.to) ? self.to.toISOString() : '';
                        d.from = angular.isDefined(self.from) ? self.from.toISOString() : '';
                    }
                })
                .withOption('order', [1, 'desc'])
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('responsive', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()
                .withOption('fnDrawCallback', function(settings) {
                    self.selected = {};
                    $.each(settings.json.data, function(k, v){
                        self.selected[v.id] = false;
                    })
                });
            self.dtColumns = [
                DTColumnBuilder.newColumn('null').withTitle('<input ng-model="Factura.selectAll" ng-click="Factura.SR.toggleAll(Factura.selectAll, Factura.selected)" type="checkbox">').notSortable().withOption('searchable', false).renderWith(function(data, type, full, meta){
                        self.selected[full.id] = false;
                        return '<input ng-model="Factura.selected[' + full.id + ']" ng-click="Factura.SR.toggleOne(Factura.selected)" type="checkbox">';
                }),
                DTColumnBuilder.newColumn('numero').withTitle('Numero'),
                DTColumnBuilder.newColumn('cliente').withTitle('Cliente').withOption('name', 'factura_detalle.nombre'),
                DTColumnBuilder.newColumn('fecha').withTitle('Fecha').withOption('name', 'factura_transaccion.fecha'),
                DTColumnBuilder.newColumn('bastidor').withTitle('Bastidor').withOption('name', 'vehiculos.bastidor'),
                DTColumnBuilder.newColumn('matricula').withTitle('Matrícula').withOption('name', 'vehiculos.matricula'),
                DTColumnBuilder.newColumn('total').withTitle('Total').withOption('name', 'vehiculos.precio_venta'),
                DTColumnBuilder.newColumn('creado').withTitle('Creado').withOption('name', 'factura_transaccion.created_at'),
                DTColumnBuilder.newColumn(null).withOption('visible', PPS.hasPermissionDefinition('admin') ? true : false).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '30px').withOption('searchable', false).withClass('all')
                    .renderWith(function(data, type, full, meta) {
                    return '<div class="buttons" style="width:30px"><button type="button" ng-click="Factura.devolucion('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-recycle"></em></button></div>';
                }),
            ];

            self.opened1 = false;
            self.opened2 = false;
            self.open = function($event, elementOpened) {
                $event.preventDefault();
                $event.stopPropagation();
                self[elementOpened] = !self[elementOpened];
            };

            self.printSelected = function(){
                var id = self.SR.getSelected(self.selected);
                if(id.length){
                    DownloadFile.download({action: 'factura_pdf'}, {id: id}, function (result) {
                        var data = new Blob([result.data], {type: result.type});
                        FileSaver.saveAs(data, result.filename);
                    });
                }else Notify.alert('Selecciona almenos una factura',{status: 'info', timeout:5000});
            }

            self.search = function(){
                self.dtInstance.reloadData();
            }

            self.reload = function() {
                self.dtInstance.reloadData();
                self.SR.resetSelected(self.selected);
            }

            self.devolucion = function(index){
                CD.confirmar('¿Seguro quieres devolver este vehículo?').then(function(){
                  self.facturak = {isNew: true, idFactura: index, texto_factura: {id: 4}};
                  if(!self.textoFacturas.length) self.textoFacturas = TextoFactura.getTextos();
                  self.modal('facturarDevolucion', 'ModalFacturaDevCtrl', self, 'lg');
                });
            }
        }
    ]);

angular.module('controllers').controller('ModalFacturaDevCtrl', ['$uibModalInstance', 'resolve', 'FacturaK', '$controller', function($MI, resolve, FacturaK, $controller) {
    var self = this;
    angular.extend(this, $controller('ModalSaveModelCtrl', {$uibModalInstance: $MI, resolve:resolve}));

    self.saveFacturaDevolucion = function(data,form){
        data.devolucion = true;
        data.idFactura = self.resolve.facturak.idFactura;
        return self.resolve.base.save(FacturaK, data, self.resolve.facturak, form).then(function(res){
            $MI.close();
            self.resolve.reload();
        })
    }

}]);