angular.module('services')
    .factory('baseModelXE', ['$q', function($q){
        var insert = false;
        var baseModel = {
            init: function(){insert = false;},
            save : function(Model, data, item, form, id){
                var d = $q.defer();
                if(item.isNew){
                    Model.save(data, function(res){
                        insert = false;
                        d.resolve(res);
                    }, function(err){
                        d.reject('Error');
                        angular.forEach(err.data, function(value, key){
                            form.$setError(key, value[0]);
                        });
                    })
                }
                else {
                    Model.update({Id: angular.isDefined(id) ? id : item.id}, data, function(res){
                        d.resolve(res);
                    }, function(err){
                        d.reject('Error');
                        angular.forEach(err.data, function(value, key){
                            form.$setError(key, value[0]);
                        });
                    });
                }
                return d.promise;
            },
            cancel : function(item){
                if(item.isNew) {
                    insert = false;
                    return true;
                }
                else return false;
            },
            add : function(){
                if(!insert){
                    insert = true;
                    return {isNew: true};
                }
                return false;
            }
        }
        return baseModel;
    }]);