angular.module('services')
    .factory('AuthInfo', ['$injector', '$localStorage', 'PermPermissionStore', function ($injector, $ls, PPS) {
        var service = {
            currentUser : null,
            hasAuthHeader: function(){
                return $ls.token ? $ls.token : false;
            },
            getAuthHeader: function(){
                return 'Bearer ' + $ls.token;
            },
            redirectToLogin: function(){
                var state =  $injector.get('$state');
                return state.go('login');
            },
            redirectToHome: function(){
                var state =  $injector.get('$state');
                return state.go('app.home');
            },
            setCurrentUser: function(user){
                var User = $injector.get('User');
                return User.login(user, function(response){
                    $ls.token = response.token;
                    service.currentUser = response.user
                    PPS.defineManyPermissions(response.user.permission, function (permissionName) {
                        return true;
                    });

                });
            },
            getCurrentUser: function(){
                if(!service.currentUser){
                    var User = $injector.get('User');
                    return User.getByToken(function(response){
                        service.currentUser = response.user;
                        PPS.defineManyPermissions(response.user.permission, function (permissionName) {;
                            return true
                        });
                    });
                }
                return service.currentUser;
            },
            deleteCurrentUser: function(){
                if(service.currentUser){
                    delete $ls.token;
                    service.currentUser = null;
                }
                window.location.reload();
            }
        }
        return service;
    }]);
