angular.module('controllers')
    .controller('FacturaKController', ['FacturaK', 'Tercero', 'Cuenta', 'TextoFactura', 'DTOptionsBuilder', 'ConfirmDelete', 'DTColumnBuilder', '$controller', '$compile', '$scope', '$filter', 'selectRows', 'Notify', 'DownloadFile', 'PermPermissionStore', 'FileSaver', 'Blob',
        function (FacturaK, Tercero, Cuenta, TextoFactura, DTOB, CD, DTColumnBuilder, $controller, $compile, $scope, $filter, selectRows, Notify, DownloadFile, PPS, FileSaver, Blob) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.Model = FacturaK;
            self.SR = selectRows;
            self.selectAll = false;
            self.dtInstance = [];
            self.cuentas = [];
            self.facturaks = [];
            self.clientes = [];
            self.textoFacturas = [];
            self.filter_fecha = 'fecha';
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/facturak/datatable',
                    type: 'GET',
                    data: function(d){
                        d.to = angular.isDefined(self.to) ? self.to.toISOString() : '';
                        d.from = angular.isDefined(self.from) ? self.from.toISOString() : '';
                        d.tipo = self.searchByTipo;
                        d.filter_fecha = self.filter_fecha;
                    }
                })
                .withOption('order', [1, 'desc'])
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('responsive', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()
                .withOption('fnDrawCallback', function(settings) {
                    self.selected = {};
                    $.each(settings.json.data, function(k, v){
                        self.selected[v.id] = false;
                    })
                });
            self.dtColumns = [
                DTColumnBuilder.newColumn('null').withTitle('<input ng-model="FacturaK.selectAll" ng-click="FacturaK.SR.toggleAll(FacturaK.selectAll, FacturaK.selected)" type="checkbox">').notSortable().withOption('searchable', false).renderWith(function(data, type, full, meta){
                        return '<input ng-model="FacturaK.selected[' + full.id + ']" ng-click="FacturaK.SR.toggleOne(FacturaK.selected)" type="checkbox">';
                }),
                DTColumnBuilder.newColumn('numero').withTitle('Número').withOption('name', 'facturaks.numero'),,
                DTColumnBuilder.newColumn('cliente').withTitle('Cliente').withOption('name', 'factura_detalle.nombre'),
                DTColumnBuilder.newColumn('base_imponible').withTitle('Base Imponible'),
                DTColumnBuilder.newColumn('iva').withTitle('IVA'),
                DTColumnBuilder.newColumn('fecha').withTitle('Fecha'),
                DTColumnBuilder.newColumn('pagado').withTitle('Pagado').renderWith(function(data, type, full, meta){
                    return '<span class="fa fa-'+(data ? 'check' : 'times')+'"></span>';
                }),
                DTColumnBuilder.newColumn('fecha_pago').withTitle('Fecha Pago'),
                DTColumnBuilder.newColumn(null).withOption('visible', PPS.hasPermissionDefinition('admin') ? true : false).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '80px').withOption('searchable', false).withClass('all')
                    .renderWith(function(data, type, full, meta) {
                    self.facturaks[data.id] = data;
                    return '<div class="buttons" style="width:80px"><button type="button" ng-click="FacturaK.pago('+data.id+')" class="mb-sm btn btn-xs btn-default"><em class="fa fa-money"></em></button> <button type="button" ng-click="FacturaK.editar('+data.id+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button> <button type="button" ng-click="FacturaK.delete('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-times"></em></button></div>';
                }),
            ]

            self.search = function(){
                self.reload();
            }

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar está factura?').then(function(){
                FacturaK.remove({Id: index}, function(res){
                    self.reload();
                });
              });
            }

            self.opened = false;
            self.opened1 = false;
            self.opened2 = false;
            self.open = function($event, elementOpened) {
                $event.preventDefault();
                $event.stopPropagation();
                self[elementOpened] = !self[elementOpened];
            };

            self.openModal = function(){
                if(!self.cuentas.length) 
                    Cuenta.activas(function(res){
                        self.cuentas = res;
                        if(!angular.isDefined(self.facturak.cuenta_bancaria_id))
                            self.facturak.cuenta_bancaria_id = self.cuentas[0].id
                    });
                else{
                    if(!angular.isDefined(self.facturak.cuenta_bancaria_id))
                            self.facturak.cuenta_bancaria_id = self.cuentas[0].id
                }
                if(!self.clientes.length) self.clientes = Tercero.getByTipo({tipo:'cliente'});
                if(!self.textoFacturas.length) self.textoFacturas = TextoFactura.getTextos();
                self.modal('createOrUpdate', 'ModalFacturaKCtrl', self, 'lg');
            }

            self.add = function(){
                self.facturak = {isNew: true, texto_factura: {id: 4}};
                self.openModal();
            }

            self.editar = function(index){
                FacturaK.get({Id: index}, function(res){
                    self.facturak = res;
                    self.facturak.fecha = new Date(self.facturak.fecha);
                    self.openModal();
                });
            }

            self.pago = function(index){
                if(self.facturaks[index].pagado) 
                    FacturaK.getFechaPago({Id: index}, function(res){
                        self.facturak = res;
                        self.facturak.fecha_pago = new Date(self.facturak.fecha_pago);
                    })
                else self.facturak = {id: index};
                self.modal('pagoFacturaK', 'ModalFacturaKCtrl', self);
            }

            self.reload = function() {
                self.dtInstance.reloadData();
                self.SR.resetSelected(self.selected);
            }

            self.printSelected = function(){
                var id = self.SR.getSelected(self.selected);
                if(id.length){
                    DownloadFile.download({action: 'facturak_pdf'}, {id: id}, function (result) {
                        var data = new Blob([result.data], {type: result.type});
                        FileSaver.saveAs(data, result.filename);
                    });
                }else Notify.alert('Selecciona almenos una factura',{status: 'info', timeout:5000});
            }

            self.unpaidReport = function(){
                DownloadFile.download({action: 'unpaidReportFacturaK'}, function(result){
                    FileSaver.saveAs(new Blob([result.data], {type: result.type}), result.filename);
                })
            }

        }
    ])
angular.module('controllers').controller('ModalFacturaKCtrl', ['$uibModalInstance', 'resolve', '$controller', function($MI, resolve, $controller) {
    angular.extend(this, $controller('ModalSaveModelCtrl', {$uibModalInstance: $MI, resolve:resolve}));
    var self = this;

    self.saveModelFactura = function(data, form, object){
        data.pagado = self.resolve.facturak.pagado;
        return self.resolve.base.save(self.resolve.Model, data, self.resolve[object], form).then(function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData();
        });
    };

    self.saveModel = function(data){
        return self.resolve.Model.pago({Id: self.resolve.facturak.id}, data, function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData();
        });
    }

}]);