angular.module('services')
	.factory('Modal', ['$uibModal', function($modal){
		return {
			open: function(template, object, validate){
				validate = validate || false;
				resolve = object || null;
				return $modal.open({
	                templateUrl: template,
	                resolve: {
                    	resolve: object
                  	},
	                controller: function($scope, $uibModalInstance, resolve) {
	                    var modal = this;
	                    modal.resolve = resolve;
	                    $scope.ok = function() {
	                      if($('.parsley-error').length === 0 && !$('form').hasClass('ng-invalid')) {
	                        $uibModalInstance.close(modal.object);
	                      }
	                    };
	                    $scope.cancel = function() {
	                        $uibModalInstance.dismiss('cancel');
	                    };
	                },
	                controllerAs: 'modal'
	            });
			}
		}
	}])