angular.module('services')
    .factory('TextoFactura', ['$resource', function ($resource) {
        return $resource('/api/textofactura/:Id', {
            Id: '@id'
            },
            {
              update: {method: 'PUT'},
              getTextos: {method: 'GET', url: 'textofactura/getTextos', isArray: true}
            }
        );
    }]);