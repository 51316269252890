angular.module('services')
    .factory('User', ['$resource', function ($resource) {
        return $resource('/api/user/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              },
              login: {
                method: 'POST',
                url: '/user/login'
              },
              getByToken: {
                method: 'GET',
                url: '/user/getByToken'
              },
              changePassword: {
                method: 'POST',
                url: '/user/cambiarPassword/:Id'
              }
            }
        );
    }]);