angular.module('controllers')
    .controller('ImportarController', ['FileUploader', 'Notify', '$localStorage', 'DownloadFile', 'FileSaver',
        function (FileUploader, Notify, $ls, DownloadFile, FileSaver) {
            var self = this;
            activate();
            function activate() {
                var uploader = self.uploader = new FileUploader({
                    autoUpload : true,
                    headers : {"X-Requested-With": 'XMLHttpRequest', 'Authorization': 'Bearer'+ $ls.token },
                });

              // FILTERS

                uploader.filters.push({
                    name: 'customFilter',
                    fn: function(item, options) {
                        return ( (item.type === 'application/csv' || item.type === 'text/csv' || item.type === 'application/vnd.ms-excel') && item.size <= 1048576) ? true : false;
                    }
                });

              // CALLBACKS

                uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
                    Notify.alert('¡Tipo de fichero no permitido o fichero superior a 1MB!', {status: 'danger', timeout: 15000});
                };

                uploader.onSuccessItem = function(fileItem, response, status, headers) {
                    Notify.alert(response.notify, {status: angular.isDefined(response.file) ? 'warning' : 'success', timeout: 5000});
                    if(angular.isDefined(response.file))
                        DownloadFile.download({action: 'file_errors'}, {file: response.file}, function (result) {
                            var data = new Blob([result.data], {type: result.type});
                            FileSaver.saveAs(data, result.filename);
                        });
                }

                uploader.onErrorItem = function(fileItem, response, status, headers) {
                    Notify.alert(response.error, {status: 'danger', timeout: 15000});
                };

            }
        }
    ]);