angular.module('services')
    .factory('selectRows', [function(){
        var selectRows = {
            toggleAll : function(selectAll, selectedItems) {
                for (var id in selectedItems) {
                    if (selectedItems.hasOwnProperty(id)) {
                        selectedItems[id] = selectAll;
                    }
                }
            },
            toggleOne : function(selectedItems) {
                for (var id in selectedItems) {
                    if (selectedItems.hasOwnProperty(id)) {
                        if(!selectedItems[id]) {
                            self.selectAll = false;
                            return;
                        }
                    }
                }
                self.selectAll = true;
            },
            resetSelected : function(selectedItems) {
                for (var id in selectedItems) {
                    if(selectedItems.hasOwnProperty(id)) delete selectedItems[id];
                }
            },
            getSelected : function(selectedItems){
                var id = [];
                angular.forEach(selectedItems, function(v, k){
                    if(v===true) id.push(k);
                })
                return id;
            }

        }
        return selectRows;
    }]);