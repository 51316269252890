angular.module('controllers')
	.controller('LoginController', ['AuthInfo', '$state',
		function(AuthInfo, $state){
			var self = this;
			self.account = {};
			self.authMsg = '';
			self.login = function () {
                AuthInfo.setCurrentUser(self.account).$promise.then(function(res){
                    $state.go('app.home');
                }, function(err){
                  self.authMsg = err.data;
                });
            };
		}
	]);