angular.module('services')
    .factory('Version', ['$resource', function ($resource) {
        return $resource('/api/version/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              },
              byIdModelo: {
                method: 'GET',
                url: 'version/IdModelo/:id_modelo',
                isArray:true
              }
            }
        );
    }]);
