angular.module('controllers')
    .controller('PagoProveedorController', ['PagoProveedor', 'Tercero', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$scope', 'selectRows', '$controller', 'ConfirmDelete', 'Notify', 'DownloadFile', 'PermPermissionStore', 'FileSaver', 'Blob',
        function (PagoProveedor, Tercero, DTOB, DTColumnBuilder, $compile, $scope, selectRows, $controller, CD, Notify, DownloadFile, PPS, FileSaver, Blob) {
            angular.extend(this, $controller('baseControllerXE', {}));
            angular.extend(this, $controller('ImportarController', {}));
            var self = this;
            self.SR = selectRows;
            self.Model = PagoProveedor;
            self.selected = {};
            self.selectAll = false;
            self.dtInstance = {};
            self.SiNo = [{id: 'si', nombre: 'Si'}, {id: 'no', nombre: 'No'}];
            self.searchByFactura = 'no';
            self.filter_fecha = 'fecha_pago';
            activate();

            function activate(){
                self.uploader.onSuccessItem = function(fileItem, response, status, headers) {
                    Notify.alert(response.notify, {status: 'success', timeout: 5000});
                    self.dtInstance.reloadData();
                }
            }
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/pago_proveedor/datatable',
                    type: 'GET',
                    data: function(d){
                        d.to = angular.isDefined(self.to) ? self.to.toISOString() : '';
                        d.from = angular.isDefined(self.from) ? self.from.toISOString() : '';
                        d.searchProveedor = self.searchProveedor;
                        d.facturado = self.searchByFactura;
                        d.filter_fecha = self.filter_fecha;
                    }
                })
                .withOption('order', [6, 'asc'])
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('responsive', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()
                .withOption('fnDrawCallback', function(settings) {
                    self.selected = {};
                    $.each(settings.json.data, function(k, v){
                        self.selected[v.id] = false;
                    })
                });
            self.dtColumns = [
                DTColumnBuilder.newColumn('null').withTitle('<input ng-model="PagoProveedor.selectAll" ng-click="PagoProveedor.SR.toggleAll(PagoProveedor.selectAll, PagoProveedor.selected)" type="checkbox">').notSortable().withOption('searchable', false).renderWith(function(data, type, full, meta){
                        self.selected[full.id] = false;
                        return '<input ng-model="PagoProveedor.selected[' + full.id + ']" ng-click="PagoProveedor.SR.toggleOne(PagoProveedor.selected)" type="checkbox">';
                }),
                DTColumnBuilder.newColumn('bastidor').withTitle('Bastidor').withOption('name', 'vehiculos.bastidor'),
                DTColumnBuilder.newColumn('matricula').withTitle('Matrícula').withOption('name', 'vehiculos.matricula'),
                DTColumnBuilder.newColumn('marca_nombre').withTitle('Marca').withOption('name', 'marcas.nombre'),
                DTColumnBuilder.newColumn('modelo_nombre').withTitle('Modelo').withOption('name', 'modelos.nombre'),
                DTColumnBuilder.newColumn('version_nombre').withTitle('Version').withOption('name', 'versiones.nombre'),
                DTColumnBuilder.newColumn('proveedor_nombre').withTitle('Proveedor').withOption('name', 'terceros.nombre'),
                DTColumnBuilder.newColumn('fecha_pago').withTitle('Fecha Pago').withOption('name', 'pago_vehiculo_proveedor.fecha_pago'),
                DTColumnBuilder.newColumn('numero_factura').withTitle('Nº Fact.').withOption('name', 'pago_vehiculo_proveedor.numero_factura'),
                DTColumnBuilder.newColumn('fecha_factura').withTitle('Fecha Fact.').withOption('name', 'pago_vehiculo_proveedor.fecha_factura'),
                DTColumnBuilder.newColumn(null).withOption('visible', PPS.hasPermissionDefinition('admin') ? true : false).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '60px').withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                    return '<div class="buttons" style="width:60px"><button type="button" ng-click="PagoProveedor.editar('+data.id+')" class="mb-sm btn btn-xs btn-warning"><em class="fa fa-pencil"></em></button> <button type="button" ng-click="PagoProveedor.delete('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-times"></em></button></div>';
                }),
            ];

            self.export = function(type){
                if(self.from == undefined || self.to == undefined){
                    Notify.alert('Introduce filtro de fechas obligatorio',{status: 'info', timeout:5000});
                }else{
                    var dataSend = {from: self.from, to: self.to, searchProveedor: self.searchProveedor, facturado: self.searchByFactura, filter_fecha : self.filter_fecha};
                    if(type=='pdf'){
                        PagoProveedor.download_pdf(dataSend, function(res){
                            self.pp_pdf = res[0];
                            self.modal('partials/pago_proveedor/pdf', 'ModalPdfCtrl', self, 'lg');
                        })
                    }
                    else{
                        DownloadFile.download({action: type !== 'no_fact' ? 'pago_proveedor_excel' : 'pago_proveedor_no_fact'}, dataSend , function (result) {
                            var data = new Blob([result.data], {type: result.type});
                            FileSaver.saveAs(data, result.filename);
                        });
                    }
                }
            }

            self.proveedores = Tercero.getByTipo({tipo: 'proveedor'});
            self.opened1 = false;
            self.opened2 = false;
            self.open = function($event, elementOpened) {
                $event.preventDefault();
                $event.stopPropagation();
                self[elementOpened] = !self[elementOpened];
            };

            self.search = function(){
                self.dtInstance.reloadData();
            }

            self.editar = function(index){
                PagoProveedor.get({Id: index}, function(res){
                    self.pago_proveedor = res;
                    if(self.pago_proveedor.fecha_factura!=null) self.pago_proveedor.fecha_factura = new Date(self.pago_proveedor.fecha_factura);
                    self.modal('factPagoProveedores', 'ModalSaveModelCtrl', self);
                });
            }

            self.eliminarSeleccionados = function(){
                var id = self.SR.getSelected(self.selected);
                if(id.length){
                    CD.confirmar('¿Seguro quieres quitar todos los pagos de vehículos seleccionados?').then(function(){
                        PagoProveedor.eliminarSeleccionados({ids: id}, function(res){
                            self.dtInstance.reloadData(); 
                        });
                    });
                }
            }

            self.editarSeleccionados = function(){
                self.pago_proveedor = [];
                var id = self.SR.getSelected(self.selected);
                if(id.length){
                    self.idPaPro = id;
                   self.modal('factPagoProveedores', 'ModalPagoProveedorCtrl', self); 
                }
            }

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres quitar el pago de este vehículo?').then(function(){
                PagoProveedor.remove({Id: index}, function(res){
                    self.dtInstance.reloadData();
                });
              });
            }
        }
    ]);
angular.module('controllers').controller('ModalPagoProveedorCtrl', ['$uibModalInstance', 'resolve', '$controller', function($MI, resolve, $controller) {
    angular.extend(this, $controller('ModalSaveModelCtrl', {$uibModalInstance: $MI, resolve:resolve}));
    var self = this;

    self.saveModel = function(data, form){
        data.ids = self.resolve.idPaPro;
        return self.resolve.base.save(self.resolve.Model, data, self.resolve.pago_proveedor, form).then(function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData();
        });
    }

}]);