angular.module('app', [
  'app.core',
  'app.routes',
  'app.sidebar',
  'app.navsearch',
  'app.preloader',
  'app.loadingbar',
  'app.settings',
  'app.dashboard',
  'app.icons',
  'app.flatdoc',
  'app.notify',
  'app.bootstrapui',
  'app.elements',
  'app.panels',
  'app.charts',
  'app.forms',
  'app.locale',
  'app.maps',
  'app.pages',
  'app.tables',
  'app.extras',
  'app.mailbox',
  'app.utils',
  'services',
  'config',
  'directives',
  'controllers',
  'permission',
  'pusher-angular',
  'ngFileSaver'
])

angular.module('services', []);
angular.module('config', []);
angular.module('directives', []);
angular.module('controllers', []);