angular.module('controllers')
    .controller('StockController', ['Stock', 'Vehiculo', 'Tercero', 'Marca', 'Modelo', 'Version', 'Equipamiento', 'Color', 'Ubicacion', 'Transaccion', 'PagoProveedor', 'DTOptionsBuilder', 'ConfirmDelete', 'DTColumnBuilder', '$controller', '$compile', '$scope', '$filter', 'Notify', 'PermPermissionStore', 'DownloadFile', 'FileSaver', 'Blob',
        function (Stock, Vehiculo, Tercero, Marca, Modelo, Version, Equipamiento, Color, Ubicacion, Transaccion, PagoProveedor, DTOB, CD, DTColumnBuilder, $controller, $compile, $scope, $filter, Notify, PPS, DownloadFile, FileSaver, Blob) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.Model = Stock;
            self.dtInstance = {};
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/stock/datatable',
                    type: 'GET',
                    data: function(d){
                        d.to = angular.isDefined(self.to) ? self.to.toISOString() : '';
                        d.from = angular.isDefined(self.from) ? self.from.toISOString() : '';
                        d.marca = self.searchMarca;
                        d.modelo = self.searchModelo;
                        d.version = self.searchVersion;
                    }
                })
                .withOption('order', [1, 'desc'])
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('responsive', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"Blfrtip>')
                .withBootstrap()
                .withButtons([{extend: 'colvis', text: 'Visibles', 'columns': [5, 7, 9, 10, 11, 12, 15]}]);
            self.dtColumns = [
                // 0
                DTColumnBuilder.newColumn('bastidor').withTitle('Bastidor').withOption('name', 'vehiculos.bastidor'),
                // 1
                DTColumnBuilder.newColumn('matricula').withTitle('Matrícula').withOption('name', 'vehiculos.matricula'),
                // 2
                DTColumnBuilder.newColumn('marca_nombre').withTitle('Marca').withOption('name', 'marcas.nombre').withOption('searchable', false),
                // 3
                DTColumnBuilder.newColumn('modelo_nombre').withTitle('Modelo').withOption('name', 'modelos.nombre').withOption('searchable', false),
                // 4
                DTColumnBuilder.newColumn('version_nombre').withTitle('Version').withOption('name', 'versiones.nombre').withOption('searchable', false),
                // 5
                DTColumnBuilder.newColumn('precio_compra').withTitle('Precio Compra').withOption('name', 'vehiculos.precio_compra').notVisible(),
                // 6
                DTColumnBuilder.newColumn('precio_venta').withTitle('Precio Venta').withOption('name', 'vehiculos.precio_venta'),
                // 7
                DTColumnBuilder.newColumn('danyo').withTitle('Daño').withOption('name', 'vehiculos.danyo').notVisible(),
                // 8
                DTColumnBuilder.newColumn('color').withTitle('Color').withOption('name', 'colores.nombre'),
                // 9
                DTColumnBuilder.newColumn('equipamientos').withTitle('Equip.').withOption('searchable', false).notVisible(),
                // 10
                DTColumnBuilder.newColumn('fecha_matriculacion').withTitle('Feha Mat.').withOption('name', 'vehiculos.fecha_matriculacion').notVisible(),
                // 11
                DTColumnBuilder.newColumn('km').withTitle('Km').withOption('name', 'vehiculos.km').notVisible(),
                // 12
                DTColumnBuilder.newColumn('ubicacion').withTitle('Ubicación').withOption('name', 'ubicaciones.nombre').notVisible(),
                // 13
                DTColumnBuilder.newColumn('proveedor_nombre').withTitle('Proveedor').withOption('name', 'terceros.nombre'),
                // 14
                DTColumnBuilder.newColumn('pagado').withTitle('Pagado').withOption('pagado', 'pago_vehiculo_proveedor.id').withOption('searchable', false).renderWith(function(data, type, full, meta){
                    return ( full.pagado != null ? '<div class="al-center">S</span>' : '<div class="al-center">N</div>');
                }),
                // 15
                DTColumnBuilder.newColumn('ref_proveedor').withTitle('Ref. Prov.').withOption('name', 'vehiculos.ref_proveedor').notVisible(),                
                // 16
                DTColumnBuilder.newColumn('nota').withTitle('Nota').withOption('name', 'notas.texto'),
                // 17
                DTColumnBuilder.newColumn('idNota').withTitle('Res.').withOption('name', 'notas.id').withOption('width', '80px').renderWith(function(data, type, full, meta){
                        return '<div class="al-center"><span class="fa fa-'+( full.idNota != null ? 'check' : 'times')+'"></span></div>';
                }),
                // 18
                DTColumnBuilder.newColumn(null).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '100px').withOption('searchable', false).withClass('all')
                    .renderWith(function(data, type, full, meta) {
                        var nota = data.hasNota ? '' : '<button type="button" ng-click="Stock.addNota('+data.id+')" class="mb-sm btn btn-xs btn-warning"><em class="fa fa-sticky-note-o"></em></button>';
                        var actions = !data.hasNota ? '' : ('<button type="button" ng-click="Stock.viewNota('+data.idNota+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button> '+(data.permission ? '<button type="button" ng-click="Stock.removeNota('+data.idNota+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button>' : ''));
                        return '<div class="buttons" style="width:85px">'+nota+' '+actions+'</div>';
                }),
            ];

            
            self.addNota = function(index) {
                self.vehiculoId = index;
                self.stock = {isNew: true};
                self.modal('partials/stock/add', 'ModalAddNotaCtrl', self);  
            };

            self.removeNota = function(index){
                Stock.remove({Id: index},function(){
                    self.dtInstance.reloadData();
                });
            };

            self.viewNota = function(index){
                Stock.get({Id: index},function(res){
                    self.stock = res;    
                    self.modal('partials/stock/add', 'ModalAddNotaCtrl', self);    
                });
            };
            self.imprimir = function(proveedor){
                Stock.data_pdf({
                    to : angular.isDefined(self.to) ? self.to.toISOString() : '',
                    from : angular.isDefined(self.from) ? self.from.toISOString() : '',
                    marca : self.searchMarca,
                    modelo : self.searchModelo,
                    version : self.searchVersion,
                }, function(res){
                    self.stock_pdf = res;
                    self.info_prov = proveedor;
                    self.modal('partials/stock/pdf', 'ModalPdfCtrl', self, 'lg');
                })
            }
            self.imprimirExcel = function(proveedor, reserva, precio){
                reserva = typeof reserva !== 'undefined' ?  true : false;
                var dataSend = {
                    to : angular.isDefined(self.to) ? self.to.toISOString() : '',
                    from : angular.isDefined(self.from) ? self.from.toISOString() : '',
                    marca : self.searchMarca,
                    modelo : self.searchModelo,
                    version : self.searchVersion,
                    proveedor : proveedor,
                    precio: precio
                };
                DownloadFile.download({action: !reserva ? 'stock_data_excel' : 'stock_reserva'}, ! reserva ? dataSend : {} , function (result) {
                        var data = new Blob([result.data], {type: result.type});
                        FileSaver.saveAs(data, result.filename);
                });
            }
            self.getNumber = function(index){
                if(index == 0 ) return self.cont = 1;
                else if( (self.stock_pdf[index].version_id==self.stock_pdf[index-1].version_id && self.stock_pdf[index].nota==null) || (self.stock_pdf[index].nota!=null && self.stock_pdf[index-1].nota!=null) ) return ++self.cont;
                else return self.cont = 1;
            }

            activate();
            function activate(){
                self.marcas = Marca.query();
                self.proveedores = [];
                self.colores = [];
                self.ubicaciones = [];
                self.equipamientos = [];
                self.dateOptions = {
                    startingDay: 1,
                }
            }

            self.dtInstanceCallback = function(dtInstance){
                self.dtInstance = dtInstance; 
            }

            self.search = function(){
                self.dtInstance.reloadData();
            }

            self.estados = [{value: 0, text: 'Usado'}, {value: 1, text: 'Nuevo'}];
            self.particular = [{value: 0, text: 'No'}, {value: 1, text: 'Si'}];

            self.showEstado = function(vehiculo) {
                var selected = $filter('filter')(self.estados, {value: vehiculo.estado});
                return selected.length ? selected[0].text : '';
            }

            self.chargeModelos = function(marcaId, version){
                if(angular.isDefined(marcaId) && marcaId!='') return Modelo.byIdMarca({id_marca: marcaId}, function(res){
                    if(angular.isDefined(version)) {
                        version.id = undefined;
                        if(angular.isDefined(version.modelo)) version.modelo.id = undefined;
                    }
                    self.modelos = res;
                    self.versiones = [];
                });
            }

            self.chargeVersiones = function(modeloId, version){
                if(angular.isDefined(modeloId) && modeloId!='') return Version.byIdModelo({id_modelo: modeloId}, function(res){
                    if(angular.isDefined(version)) version.id = undefined;
                    self.versiones = res;
                });
            }

            self.selectedModelo = function(model){
                if(!angular.isDefined(self.vehiculo.version.modelo)) self.vehiculo.version = {modelo: {id:model}};
                else self.vehiculo.version.modelo.id= model;
            }

            self.selectedVersion = function(model){
                self.vehiculo.version.id = model;
            }

            self.reload = function() {
                self.dtInstance.reloadData();
            }

            self.opened1 = false;
            self.opened2 = false;
            self.open = function(elementOpened) {
                self[elementOpened] = !self[elementOpened];
            };

        }
    ])

angular.module('controllers').controller('ModalAddNotaCtrl', ['$uibModalInstance', 'resolve', '$controller', function($MI, resolve, $controller) { 
    angular.extend(this, $controller('ModalSaveModelCtrl', {$uibModalInstance: $MI, resolve:resolve}));
    var self = this;

    self.saveNota = function(data, form){
        if(self.resolve.stock.isNew) data.vehiculo_id = self.resolve.vehiculoId;
        return self.saveModel(data, form, 'stock');
    }

}]);
