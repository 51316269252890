angular.module('controllers')
    .controller('PoblacionController', ['Poblacion', 'Provincia', 'Pais', 'ConfirmDelete', '$controller', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$scope', 'PermPermissionStore',
        function (Poblacion, Provincia, Pais, CD, $controller, DTOB, DTColumnBuilder, $compile, $scope, PPS) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.Model = Poblacion;
            self.dtInstance = {};
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/poblacion/datatable',
                    type: 'GET',
                })
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()

            self.dtColumns = [
                DTColumnBuilder.newColumn('id').withTitle('ID').withOption('name', 'poblaciones.id'),
                DTColumnBuilder.newColumn('nombre').withTitle('Nombre').withOption('name', 'poblaciones.nombre'),
                DTColumnBuilder.newColumn('provincia').withTitle('Provincia').withOption('name', 'provincias.nombre'),
                DTColumnBuilder.newColumn('pais').withTitle('País').withOption('name', 'paises.nombre'),
                DTColumnBuilder.newColumn('created_at').withTitle('Creado').withOption('name', 'poblaciones.created_at'),
                DTColumnBuilder.newColumn(null).withOption('visible', PPS.hasPermissionDefinition('admin') ? true : false).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '100px').withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                    return '<button type="button" ng-click="Poblacion.editar('+data.id+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button> <button type="button" ng-click="Poblacion.delete('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button>';
                }),
            ];

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar esta población?').then(function(){
                Poblacion.remove({Id: index}, function(res){
                    self.dtInstance.reloadData();
                });
              });
            }

            self.chargeProvincias = function(paisId, poblacion){
                if(angular.isDefined(paisId)) return Provincia.byIdPais({id_pais: paisId}, function(res){
                    if(angular.isDefined(poblacion) && angular.isDefined(poblacion.provincia)) poblacion.provincia.id = undefined;
                    self.provincias = res;
                });
            }

            self.add = function(){   
                self.poblacion = {isNew: true};
                if(!angular.isDefined(self.paises)) self.paises = Pais.query();
                self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
            }

            self.editar = function(index){
                Poblacion.get({Id: index}, function(res){
                    self.poblacion = res;
                    self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
                    if(!angular.isDefined(self.paises)) self.paises = Pais.query();
                });
            }
        }
    ]);