angular.module('controllers')
    .controller('ProformaController', ['Proforma', 'Tercero', 'Cuenta', 'Transaccion', 'Factura', 'TextoFactura', 'DTOptionsBuilder', 'ConfirmDelete', 'DTColumnBuilder', '$controller', '$compile', '$scope', '$filter', 'selectRows', 'Notify', 'DownloadFile', 'PermPermissionStore', 'FileSaver', 'Blob',
        function (Proforma, Tercero, Cuenta, Transaccion, Factura, TextoFactura, DTOB, CD, DTColumnBuilder, $controller, $compile, $scope, $filter, selectRows, Notify, DownloadFile, PPS, FileSaver, Blob) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.SR = selectRows;
            self.selectAll = false;
            self.dtInstance = {};
            self.cuentas = [];
            self.proformas = {};
            self.cacheClientes = [];
            self.vehiculos = [];
            self.facturado = '0';
            self.textoFacturas = [];
            self.dateOptions = {
                startingDay: 1,
            }
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/proforma/datatable',
                    type: 'GET',
                    data: function(d){
                        d.to = angular.isDefined(self.to) ? self.to.toISOString() : '';
                        d.from = angular.isDefined(self.from) ? self.from.toISOString() : '';
                        d.facturado = self.facturado;
                    }
                })
                .withOption('order', [1, 'desc'])
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('responsive', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"Blfrtip>')
                .withBootstrap()
                .withButtons([{extend: 'colvis', text: 'Visibles', 'columns': [2]}])
                .withOption('fnDrawCallback', function(settings) {
                    self.selected = {};
                    $.each(settings.json.data, function(k, v){
                        self.selected[v.id] = false;
                    })
                });
            self.dtColumns = [
                DTColumnBuilder.newColumn('null').withTitle('<input ng-model="Proforma.selectAll" ng-click="Proforma.SR.toggleAll(Proforma.selectAll, Proforma.selected)" type="checkbox">').notSortable().withOption('searchable', false).renderWith(function(data, type, full, meta){
                        return '<input ng-model="Proforma.selected[' + full.id + ']" ng-click="Proforma.SR.toggleOne(Proforma.selected)" type="checkbox">';
                }),
                DTColumnBuilder.newColumn('id').withTitle('Número').withOption('name', 'proformas.id'),
                DTColumnBuilder.newColumn('ant_numero').withTitle('Num. Anterior').withOption('name', 'proformas.numero').notVisible(),
                DTColumnBuilder.newColumn('nombreCliente').withTitle('Cliente').withOption('name', 'c.nombre'),
                DTColumnBuilder.newColumn('precio_transporte').withTitle('Transporte'),
                DTColumnBuilder.newColumn('fecha').withTitle('Fecha'),
                DTColumnBuilder.newColumn('tipo').withTitle('Tipo').renderWith(function(data, type, full, meta){
                        return self.showTipo(full);
                }),
                DTColumnBuilder.newColumn(null).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '80px').withOption('searchable', false).withClass('all')
                    .renderWith(function(data, type, full, meta) {
                    self.proformas[data.id] = data;
                    var btn_trans = '';
                    if(data.closed && parseFloat(data.precio_transporte) > 0) btn_trans = '<button type="button" ng-click="Proforma.facturarTransporte('+data.id+')" class="mb-sm btn btn-xs btn-default"><em class="fa fa-truck"></em></button> '
                    if(PPS.hasPermissionDefinition('comercial'))
                        var buttons = '<button type="button" ng-click="Proforma.print('+data.id+')" class="mb-sm btn btn-xs btn-default"><em class="fa fa-print"></em></button>';
                    if(PPS.hasPermissionDefinition('admin'))
                        var buttons = '<div class="buttons" style="width:80px">'+btn_trans+'<button type="button" ng-click="Proforma.editar('+data.id+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button> <button type="button" ng-click="Proforma.print('+data.id+')" class="mb-sm btn btn-xs btn-default"><em class="fa fa-print"></em></button> <button type="button" ng-click="Proforma.delete('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-times"></em></button></div>';
                    return buttons;
                }),
            ]

            self.search = function(){
                self.reload();
            }

            self.tipos = [{value: 0, text: 'Op. Triangular'}, {value: 1, text: 'Rebu'}, {value: 2, text: 'Neto'}, {value: 3, text: 'IVA'}, {value: 5, text: 'PARTICULAR NUEVO'}];

            self.showTipo = function(proforma) {
                var selected = $filter('filter')(self.tipos, {value: proforma.tipo});
                return selected.length ? selected[0].text : '';
            }

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar esta proforma?').then(function(){
                Proforma.remove({Id: index}, function(res){
                    self.reload();
                });
              });
            }

            self.opened = false;
            self.opened1 = false;
            self.opened2 = false;
            self.opened3 = false;
            self.open = function($event, elementOpened) {
                $event.preventDefault();
                $event.stopPropagation();
                self[elementOpened] = !self[elementOpened];
            };

            self.print = function(index){
                DownloadFile.download({action: 'proforma_pdf'}, {id: index}, function (result) {
                    var data = new Blob([result.data], {type: result.type});
                    FileSaver.saveAs(data, result.filename);
                });
            }

            self.noFacturadas = function(){
                Proforma.no_facturadas(function(res){
                    self.prof_no_facts = res;
                    self.modal('partials/proforma/no_facturadas', 'ModalPdfCtrl', self, 'lg');
                })
            }

            self.openModal = function(){
                self.Model = Proforma;
                if(!self.cuentas.length) 
                    Cuenta.activas(function(res){
                        self.cuentas = res;
                        if(!angular.isDefined(self.proforma.cuenta_bancaria_id))
                        self.proforma.cuenta_bancaria_id = self.cuentas[0].id
                    });
                else{
                    if(!angular.isDefined(self.proforma.cuenta_bancaria_id))
                        self.proforma.cuenta_bancaria_id = self.cuentas[0].id
                }

                self.modal('createOrUpdate', 'ModalSaveModelCtrl', self, 'lg');
            }

            self.add = function(){
                self.proforma = {isNew: true, tipo:0, precio_transporte: 0};
                self.vehiculos = [];
                if(!self.cacheClientes.length) 
                    Tercero.getByTipo({tipo: 'cliente'}, function(res){
                        self.cacheClientes = res;
                        self.clientes = res;
                    });
                else self.clientes = self.cacheClientes;
                self.openModal();
            }

            self.editar = function(index){
                Proforma.get({Id: index}, function(res){
                    self.proforma = res;
                    self.proforma.fecha = new Date(self.proforma.fecha);
                    self.proforma.cliente = self.proforma.transacciones[0].cliente;
                    self.clientes = [];
                    self.clientes.push(self.proforma.transacciones[0].cliente);
                    self.getVehiculos(self.proforma.cliente.id);
                    self.openModal();
                });
            }

            self.reload = function() {
                self.dtInstance.reloadData();
                self.SR.resetSelected(self.selected);
            }

            self.facturar = function(){
                self.idPro = [];
                $.each(self.selected, function(k, v){
                    if(v===true){
                        if(self.proformas[k].closed){
                            Notify.alert('Proforma ya facturada', {status: 'danger', timeout: 5000});
                            self.idPro = [];
                            return false;
                        }
                        else self.idPro.push(k);
                    }
                });
                if(self.idPro.length){
                    self.modal('facturarVehiculo', 'ModalFacturaCtrl', self);
                }
            }

            self.getVehiculos = function (cliente){
                Transaccion.getParaProforma({idCliente: cliente}, function(res){
                    self.vehiculos = res;
                    if(angular.isDefined(self.proforma.transacciones)) {
                        self.vehiculos.push.apply(self.vehiculos, self.proforma.transacciones);
                        self.proforma.transaccion = [];
                        angular.forEach(self.proforma.transacciones, function(value, key){
                            self.proforma.transaccion.push(value.id);
                        });
                    }
                })
            }

            self.facturarTransporte = function(index){
                self.facturak = {isNew: true, idProforma: index, texto_factura: {id: 4}};
                if(!self.textoFacturas.length) self.textoFacturas = TextoFactura.getTextos();
                self.modal('partials/proforma/facturarTransporte', 'ModalFacturaCtrl', self, 'lg');
            }
        }
    ])

angular.module('controllers').controller('ModalFacturaCtrl', ['$uibModalInstance', 'resolve', 'Factura', 'FacturaK', '$controller', function($MI, resolve, Factura, FacturaK, $controller) {
    angular.extend(this, $controller('ModalSaveModelCtrl', {$uibModalInstance: $MI, resolve:resolve}));
    var self = this;
    self.factura = {isNew:true};
    self.showCuenta = [{value: 1, text: 'Si'}, {value: 0, text: 'No'}];

    self.crearFactura = function(data, form){
        data.ids = self.resolve.idPro;
        return self.resolve.base.save(Factura, data, self.factura, form).then(function(res){
            $MI.close();
            self.resolve.reload();
        });
    }

    self.saveFacturaTransporte = function(data,form){
        data.transporte = true;
        data.idProforma = self.resolve.facturak.idProforma;
        return self.resolve.base.save(FacturaK, data, self.resolve.facturak, form).then(function(res){
            $MI.close();
            self.resolve.reload();
        })
    }

}]);