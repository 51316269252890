angular.module('controllers') 
    .controller('TransaccionController', ['Transaccion', 'DTOptionsBuilder', 'ConfirmDelete', 'DTColumnBuilder', '$compile', '$scope', '$filter', 
        function (Transaccion, DTOB, CD, DTColumnBuilder, $compile, $scope, $filter) { 
            var self = this; 
            self.dtInstance = {}; 
            self.dtOptions = DTOB.newOptions() 
                .withOption('ajax', { 
                    dataSrc: 'data', 
                    url: '/api/transaccion', 
                    type: 'GET', 
                    data: function(d){ 
                        d.to = angular.isDefined(self.to) ? self.to.toISOString() : ''; 
                        d.from = angular.isDefined(self.from) ? self.from.toISOString() : ''; 
                    } 
                }) 
                .withOption('order', [1, 'desc']) 
                .withOption('serverSide', true) 
                .withOption('processing', true) 
                .withOption('createdRow', function(row, data, dataIndex) { 
                    $compile(angular.element(row).contents())($scope); 
                }) 
                .withOption('headerCallback', function(header) { 
                    if (!self.headerCompiled) { 
                        self.headerCompiled = true; 
                        $compile(angular.element(header).contents())($scope); 
                    } 
                }) 
                .withDOM('<"table-responsive"lfrtip>') 
                .withBootstrap() 
            self.dtColumns = [ 
                DTColumnBuilder.newColumn('cliente').withTitle('Cliente').withOption('name', 'cliente.nombre'), 
                DTColumnBuilder.newColumn('matricula').withTitle('Matricula').withOption('name', 'vehiculo.matricula'), 
                DTColumnBuilder.newColumn('bastidor').withTitle('Bastidor').withOption('name', 'vehiculo.bastidor'), 
                DTColumnBuilder.newColumn('precio_compra').withTitle('Precio Compra').withOption('name', 'vehiculo.precio_compra'), 
                DTColumnBuilder.newColumn('precio_venta').withTitle('Precio Venta').withOption('name', 'vehiculo.precio_venta'), 
                DTColumnBuilder.newColumn('tipo').withTitle('Tipo').withOption('name', 'transacciones.tipo').renderWith(function(data, type, full, meta){ 
                        return self.showTipo(full); 
                }), 
                DTColumnBuilder.newColumn(null).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '80px').withOption('searchable', false) 
                    .renderWith(function(data, type, full, meta) { 
                    return '<button type="button" ng-click="Transaccion.delete('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-times"></em></button>'; 
                }), 
            ] 
 
            self.search = function(){ 
                self.dtInstance.reloadData(); 
            } 
 
            self.tipos = [{value: 0, text: 'Op. Triangular'}, {value: 1, text: 'Rebu'}, {value: 2, text: 'IVA'}]; 
 
            self.showTipo = function(transaccion) { 
                var selected = $filter('filter')(self.tipos, {value: transaccion.tipo}); 
                return selected.length ? selected[0].text : ''; 
            } 
 
            self.delete = function(index){ 
              CD.confirmar('¿Seguro quieres desasignar esta transaccion?').then(function(){ 
                Transaccion.remove({Id: index}, function(res){ 
                    self.dtInstance.reloadData(); 
                }); 
              }); 
            } 
 
            self.opened1 = false; 
            self.opened2 = false; 
            self.open = function(elementOpened) { 
                self[elementOpened] = !self[elementOpened]; 
            }; 
        } 
    ])