angular.module('services')
    .factory('AuthInterceptor',['AuthInfo', '$q', 'Notify', function(AuthInfo, $q, Notify) {
        return {
            request: function(config) {
                if (AuthInfo.hasAuthHeader()) {
                    var canceler = $q.defer();
                    config.timeout = canceler.promise;
                    if(config.url === '/partials/user/login') canceler.resolve();
                    config.headers.Authorization = AuthInfo.getAuthHeader();
                    $.ajaxSetup({
                        headers: {
                            'Authorization': AuthInfo.getAuthHeader()
                        }
                    });
                }
                return config;
            },
            response: function(response){
                if (angular.isDefined(response.data.notify)) Notify.alert(response.data.notify, {status: 'success', timeout: 5000});
                return response || $q.when(response);
            },
            responseError: function(res) {
                if (res.status === 400 || res.status === 401) {
                  AuthInfo.redirectToLogin();
                }
                if (res.status === 403 || res.status === 404){
                    Notify.alert(res.data, {status: 'danger', timeout: 5000});
                    AuthInfo.redirectToHome();
                }
                if(res.status === 422){
                    if (angular.isDefined(res.data.error)) Notify.alert(res.data.error, {status: 'danger', timeout: 5000});
                }
                return $q.reject(res);
            }
        };
    }])