angular.module('controllers')
    .controller('LoteVehiculoController', [ 'LoteVehiculo',  'ConfirmDelete', '$controller', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$scope', 'selectRows', '$state', 'DownloadFile',
        function ( LoteVehiculo, CD, $controller, DTOB, DTColumnBuilder, $compile, $scope, selectRows, $state, DownloadFile) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.lote = $state.params.lote;
            self.Model = LoteVehiculo;

            if(self.lote===null){
                $state.go('app.lote');
            }else{
                self.dtInstance = {};
                self.dtOptions = DTOB.newOptions()
                    .withOption('ajax', {
                        dataSrc: 'data',
                        url: '/lote/vehiculosAsociados/'+self.lote.id+'/datatable',
                        type: 'GET',
                        data: function(d){
                            d.to = angular.isDefined(self.to) ? self.to.toISOString() : '';
                            d.from = angular.isDefined(self.from) ? self.from.toISOString() : '';
                         }
                    })
                    .withOption('serverSide', true)
                    .withOption('processing', true)
                    .withOption('createdRow', function(row, data, dataIndex) {
                        $compile(angular.element(row).contents())($scope);
                    })
                    .withOption('headerCallback', function(header) {
                        if (!self.headerCompiled) {
                            self.headerCompiled = true;
                            $compile(angular.element(header).contents())($scope);
                        }
                    })
                    .withDOM('<"table-responsive"lfrtip>')
                    .withBootstrap();

                self.dtColumns = [                                     
                    DTColumnBuilder.newColumn('cliente_nombre')
                        .withTitle('Cliente')
                        .withOption('name','cliente.nombre'),
                    DTColumnBuilder.newColumn('ref_proveedor')
                        .withTitle('Referencia proveedor')
                        .withOption('name','vehiculos.ref_proveedor'),
                    DTColumnBuilder.newColumn('color')
                        .withTitle('Color')
                        .withOption('name','colores.nombre'),
                    DTColumnBuilder.newColumn('matricula')
                        .withTitle('Matricula')
                        .withOption('name','vehiculos.matricula'),
                    DTColumnBuilder.newColumn('bastidor')
                        .withTitle('Bastidor')
                        .withOption('name','vehiculos.bastidor'),
                    DTColumnBuilder.newColumn('version')
                        .withTitle('Version')
                        .withOption('name','versiones.nombre'),
                    DTColumnBuilder.newColumn('modelo')
                        .withTitle('Modelo')
                        .withOption('name','modelos.nombre'),
                    DTColumnBuilder.newColumn('marca')
                        .withTitle('Marca')
                        .withOption('name','marcas.nombre'),
                    DTColumnBuilder.newColumn('ubicacion')
                        .withTitle('Ubicación')
                        .withOption('name','ubicaciones.nombre'),
                    DTColumnBuilder.newColumn(null)
                        .withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '100px').withOption('searchable', false)
                        .renderWith(function(data, type, full, meta) {
                        return '<button type="button" ng-click="LoteVeh.delete('+data['vehiculo_id']+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button>';
                    }),
                ];
            }

            self.print = function(){
                DownloadFile.download({action: 'lote_vehiculo_excel'}, {id: self.lote.id}, function (result) {
                    var fileURL = window.URL.createObjectURL(new Blob([result.data]));
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.target = '_blank';
                    a.href = fileURL;
                    a.download = result.filename;
                    a.click();
                });
            }

            self.delete = function(index){
                CD.confirmar('¿Seguro quieres eliminar este vehiculo?').then(function(){
                    LoteVehiculo.remove({Id: index}, function(res){
                        self.dtInstance.reloadData();
                    });
                });
            }
        }
]);