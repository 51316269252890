angular.module('services')
    .factory('Tercero', ['$resource', function ($resource) {
        return $resource('/api/tercero/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              },
              getByTipo: {
                method: 'GET',
                url: 'tercero/:tipo',
                isArray: true
              }
            }
        );
    }]);