angular.module('services')
    .factory('Cuenta', ['$resource', function ($resource) {
        return $resource('/api/cuenta_bancaria/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              },
              changeStatus: {
                method: 'PATCH',
                url: 'cuenta_bancaria/changeStatus/:Id',
              },
              activas: { method:'GET', url:'cuenta_bancaria/activas', isArray: true }
            }
        );
    }]);