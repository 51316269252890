angular.module('services')
    .factory('ConfirmDelete', ['ngDialog', function (ngDialog) {
        return {
          confirmar: function(text){
            return ngDialog.openConfirm({
              template: '<div class="ngdialog-message"><div class="text-center"><span class="fa fa-warning fa-3x"></span></div><h3>'+text+'</h3>'+
              '<div class="row"><div class="col-sm-12"><button type="button" ng-click="closeThisDialog()" class="btn btn-default pull-right">Cancelar</button><button type="button" ng-click="confirm()" class="btn btn-danger mr pull-right">Confirmar</button></div></div></div>',
              plain: true,
              className: 'ngdialog-theme-default',
            });
          }
        }
    }]);