angular.module('services')
    .factory('DownloadFile', ['$resource', function($resource) {  

        function parseHeaderFilename(headers){
            var header = headers('content-disposition');
            if(header!==null){
                var result = header.split(';')[1].trim().split('=')[1];
                return result.replace(/"/g, '');
            }
        }
        return $resource('/download/:action', {action: '@action'},
            {
                download: {
                    method: 'POST',
                    responseType: 'arraybuffer',
                    transformResponse: function(data, headers) {
                        return {
                            data: data,
                            filename: parseHeaderFilename(headers),
                            type: headers('content-type')
                        }
                    }
                }
            });
    }]);