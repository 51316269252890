angular.module('controllers')
    .controller('baseControllerXE', ['validateXEditable', 'baseModelXE', 'editableOptions', 'editableThemes', '$uibModal',
        function (vxe, base, EO, ET, $modal) {
            var self = this;
            self.base = base;
            self.base.init();
            self.vxe = vxe;
            EO.theme = 'bs3';
            ET.bs3.inputClass = 'input-sm';

            self.isNumber = angular.isNumber;

            self.modal = function(tpl, controller, resolve, size){
                return $modal.open({
                    templateUrl: tpl,
                    controller: controller,
                    controllerAs: 'modal',
                    resolve: {resolve: resolve},
                    size: size,
                });
            }
        }
    ]);