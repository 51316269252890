angular.module('services')
    .factory('Transportista', ['$resource', function ($resource) {
        return $resource('/api/transportista/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              },
              vehiculosAsociados: { method: 'GET', url: 'transportista/getVehiculosAsociados/:Id', isArray: true }
            }
        );
    }]);