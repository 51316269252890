angular.module('controllers')
    .controller('EquipamientoController', ['Equipamiento', 'ConfirmDelete', '$controller', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$scope', 'PermPermissionStore',
        function (Equipamiento, CD, $controller, DTOB, DTColumnBuilder, $compile, $scope, PPS) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.Model = Equipamiento;
            self.dtInstance = {};
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/equipamiento/datatable',
                    type: 'GET',
                })
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()

            self.dtColumns = [
                DTColumnBuilder.newColumn(0).withTitle('ID'),
                DTColumnBuilder.newColumn(1).withTitle('Nombre'),
                DTColumnBuilder.newColumn(2).withTitle('Creado'),
                DTColumnBuilder.newColumn(null).withOption('visible', PPS.hasPermissionDefinition('admin') ? true : false).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '100px').withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                    return '<button type="button" ng-click="Equipamiento.editar('+data[0]+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button> <button type="button" ng-click="Equipamiento.delete('+data[0]+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button>';
                }),
            ];

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar este equipamiento?').then(function(){
                Equipamiento.remove({Id: index}, function(res){
                    self.dtInstance.reloadData();
                });
              });
            }

            self.add = function(){   
                self.equipamiento = {isNew: true};
                self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
            }

            self.editar = function(index){
                Equipamiento.get({Id: index}, function(res){
                    self.equipamiento = res;
                    self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
                });
            }
        }
    ]);