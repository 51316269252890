angular.module('services')
    .factory('validateXEditable', [function(){
        var validate = {
            check : true,
            checkRequired: function(data, name){
                if(data === "" || !angular.isDefined(data) || data === null || ($.isArray(data) && data.length <= 0) ) return 'El campo '+name+' es obligatorio';
                return true;
            },
            //Solo funciona para campos de type text
            checkIsInteger: function(data, name, required){
                required = required || false;
                validate.check = validate.checkRequired(data, name);
                if(required && validate.check!==true) return validate.check;
                else if(validate.check === true && !$.isNumeric(data)) return 'El campo '+name+' debe ser númerico';
                return true;

            }
        }
        return validate;
    }])