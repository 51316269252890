angular.module('services')
    .factory('FacturaK', ['$resource', function ($resource) {
        return $resource('/api/facturak/:Id', {
            Id: '@id'
            },
            {
                update: { method: 'PUT' },
                getFechaPago: { method: 'GET', url: 'facturak/getFechaPago/:Id'},
                pago: { method: 'POST', url: 'facturak/pago/:Id'},
            }
        );
    }]);