angular.module('controllers')
    .controller('VersionController', ['Version', 'Modelo', 'Marca', '$filter', '$controller', 'DTOptionsBuilder', 'DTColumnBuilder', 'ConfirmDelete', '$compile', '$scope', 'PermPermissionStore',
        function (Version, Modelo, Marca, $filter, $controller, DTOB, DTColumnBuilder, CD, $compile, $scope, PPS) {

            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.Model = Version;
            self.dtInstance = {};
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/version/datatable',
                    type: 'GET',
                })
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()

            self.dtColumns = [
                DTColumnBuilder.newColumn('id').withTitle('ID').withOption('name', 'versiones.id'),
                DTColumnBuilder.newColumn('nombre').withTitle('Nombre').withOption('name', 'versiones.nombre'),
                DTColumnBuilder.newColumn('modelo').withTitle('Modelo').withOption('name', 'modelos.nombre'),
                DTColumnBuilder.newColumn('marca').withTitle('Marca').withOption('name', 'marcas.nombre'),
                DTColumnBuilder.newColumn('puertas').withTitle('Puertas').withOption('name', 'versiones.puertas'),
                DTColumnBuilder.newColumn('co2').withTitle('CO2').withOption('name', 'versiones.co2'),
                DTColumnBuilder.newColumn('cc').withTitle('CC').withOption('name', 'versiones.cc'),
                DTColumnBuilder.newColumn('gd').withTitle('GD').withOption('name', 'versiones.gd').renderWith(function(data, type, full, meta){
                    return self.showCombustible(full);
                }),
                DTColumnBuilder.newColumn(null).withOption('visible', PPS.hasPermissionDefinition('admin') ? true : false).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', '100px').withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                    return '<button type="button" ng-click="Version.editar('+data.id+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button> <button type="button" ng-click="Version.delete('+data.id+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button>';
                }),
            ];

            self.combustibles = [{value: 0, text: 'Gasolina'}, {value: 1, text: 'Diesel'}];

            self.showCombustible = function(version) {
                if(angular.isDefined(version)){
                    var selected = $filter('filter')(self.combustibles, {value: version.gd});
                    return (version.gd && selected.length) ? selected[0].text : '';
                }
            }

            self.chargeModelos = function(marcaId, version){
                if(angular.isDefined(marcaId)) return Modelo.byIdMarca({id_marca: marcaId}, function(res){
                    if(angular.isDefined(version) && angular.isDefined(version.modelo)) version.modelo.id = undefined;
                    self.modelos = res;
                });
            }

            self.delete = function(index){
              CD.confirmar('¿Seguro quieres eliminar esta Version?').then(function(){
                Version.remove({Id: index}, function(res){
                    self.dtInstance.reloadData();
                });
              });
            }

            self.add = function(){   
                self.version = {isNew: true};
                if(!angular.isDefined(self.marcas)) self.marcas = Marca.query();
                self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
            }

            self.editar = function(index){
                Version.get({Id: index}, function(res){
                    self.version = res;
                    self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
                    if(!angular.isDefined(self.marcas)) self.marcas = Marca.query();
                });
            }
        }
    ]);