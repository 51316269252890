angular.module('services')
    .factory('Empresa', ['$resource', function ($resource) {
        return $resource('/api/empresa/:Id', {
            Id: '@id'
            },
            {
                getDatos: {
                    method: 'GET',
                    url: 'empresa/getDatos',
                },
                update: {
                    method: 'POST',
                    url: 'empresa/update',
                }
            }
        );
    }]);
