angular.module('directives')
  .directive('formErrors', [function(){
    return {
      restrict: 'AE',
      scope: {
        errors: '='
      },
      template: '<div ng-if="isObject(errors)">'+
        '<div ng-repeat="(key, error) in errors">'+
        '<span>{{error}}</span>'+
        '</div></div>'+
        '<div ng-if="!isObject(errors)"><span>{{errors}}</span>',
      link: function($scope){
        $scope.isObject = function(errors){
            return angular.isObject(errors);
        }
      }
    }
  }]);