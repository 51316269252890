angular.module('controllers')
.controller('TransportistaController', ['Vehiculo', 'Transportista', 'ConfirmDelete', '$controller', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$scope', 'DownloadFile', 'PermPermissionStore', 'FileSaver', 'Blob',
    function (Vehiculo, Transportista, CD, $controller, DTOB, DTColumnBuilder, $compile, $scope, DownloadFile, PPS, FileSaver, Blob) {
        angular.extend(this, $controller('baseControllerXE', {}));
        var self = this;
        self.Model = Transportista;
        self.transportistas = [];
        self.dtInstance = {};
        self.dtOptions = DTOB.newOptions()
            .withOption('ajax', {
                dataSrc: 'data',
                url: '/transportista/datatable',
                type: 'GET',
            })
            .withOption('serverSide', true)
            .withOption('processing', true)
            .withOption('createdRow', function(row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function(header) {
                if (!self.headerCompiled) {
                    self.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withDOM('<"table-responsive"lfrtip>')
            .withBootstrap()

        self.dtColumns = [
            DTColumnBuilder.newColumn(0).withTitle('ID'),
            DTColumnBuilder.newColumn(1).withTitle('Nombre'),
            DTColumnBuilder.newColumn(2).withTitle('Creado'),
            DTColumnBuilder.newColumn(null).withTitle('<span class="fa fa-flash"></span>').notSortable().withOption('width', (PPS.hasPermissionDefinition('admin') ? '130px' : '80px') ).withOption('searchable', false)
                .renderWith(function(data, type, full, meta) {
                self.transportistas[data[0]] = { nombre: data[1], creado: data[2] } ;
                return '<button type="button" ng-click="Transportista.excel('+data[0]+')" class="mb-sm btn btn-xs btn-success"><em class="fa fa-file-excel-o"></em></button> '+(PPS.hasPermissionDefinition('admin') ? '<button type="button" ng-click="Transportista.editar('+data[0]+')" class="mb-sm btn btn-xs btn-info"><em class="fa fa-eye"></em></button> ' : '') + ' <button type="button" ng-click="Transportista.showVehiculos('+data[0]+')" class="mb-sm btn btn-xs btn-warning"><em class="fa fa-car"></em></button> ' +(PPS.hasPermissionDefinition('admin') ? '<button type="button" ng-click="Transportista.delete('+data[0]+')" class="mb-sm btn btn-xs btn-danger"><em class="fa fa-trash-o"></em></button>' : '');
            }),
        ];

        self.delete = function(index){
          CD.confirmar('¿Seguro quieres eliminar esta transportista?').then(function(){
            Transportista.remove({Id: index}, function(res){
                self.dtInstance.reloadData();
            });
          });
        }

        self.add = function(){   
            self.transportista = {isNew: true};
            self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
        }

        self.editar = function(index){
            Transportista.get({Id: index}, function(res){
                self.transportista = res;
                self.modal('createOrUpdate', 'ModalSaveModelCtrl', self);
            });
        }

        self.excel = function(index){
            DownloadFile.download({action: 'transportista_vehiculo_excel'}, {id: index}, function (result) {
                var data = new Blob([result.data], {type: result.type});
                FileSaver.saveAs(data, result.filename);
            });
        }

        self.showVehiculos = function(index){
            self.idTransportista = index;
            self.vehiculosId = [];
            Transportista.vehiculosAsociados({ "Id" :  index},function(res){
                self.vehiculos = res;
                angular.forEach(self.vehiculos,function (v,k) {
                    self.vehiculosId.push(v.vehiculo_id);
                });
            });
            
            self.modal('partials/transportista/vehiculosAsociados', 'ModalTransportistaVehiculoCtrl', self, 'lg');
        }
    }
]);

angular.module('controllers').controller('ModalTransportistaVehiculoCtrl', ['TransportistaVehiculo','$uibModalInstance', 'resolve', '$controller', function (TransportistaVehiculo, $MI, resolve, $controller) {
    angular.extend(this, $controller('ModalSaveModelCtrl', {$uibModalInstance: $MI, resolve:resolve}));
    var self = this;
    var tId = self.resolve.idTransportista;
    self.transportista = self.resolve.transportistas[tId];

    self.saveModel = function(data, form){
        data.transportista_id = tId
         return TransportistaVehiculo.sincronizarVehiculos( data, function(res){
            $MI.close();
            self.resolve.dtInstance.reloadData();
        },function(err){
            self.resolve.Notify.alert(err.data.error,{status: 'danger', timeout:5000})
        })
    }
    
}]);