angular.module('services')
    .factory('ComisionistaVehiculo', ['$resource', function ($resource) {
        return $resource('api/comisionista_vehiculo/:Id', {
            Id: '@id'
            },
            {
              update: {
                method: 'PUT'
              },
            }
        );
    }]);