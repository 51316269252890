angular.module('controllers')
    .controller('ControlDocumentosController', ['DocumentoVehiculo', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$scope', 'ConfirmDelete', '$controller',
        function (DocumentoVehiculo, DTOB, DTColumnBuilder, $compile, $scope, CD, $controller) {
            angular.extend(this, $controller('baseControllerXE', {}));
            var self = this;
            self.dtInstance = {};
            self.vehiculos = [];
            self.SiNo = [{id: 'si', nombre: 'Si'}, {id: 'no', nombre: 'No'}];
            self.completo = 'no';
            self.dtOptions = DTOB.newOptions()
                .withOption('ajax', {
                    dataSrc: 'data',
                    url: '/documento_vehiculo/datatable',
                    type: 'GET',
                    data: function(d){
                        d.to = angular.isDefined(self.to) ? self.to.toISOString() : '';
                        d.from = angular.isDefined(self.from) ? self.from.toISOString() : '';
                        d.completo =  self.completo;
                    }
                })
                .withOption('serverSide', true)
                .withOption('processing', true)
                .withOption('responsive', true)
                .withOption('createdRow', function(row, data, dataIndex) {
                    $compile(angular.element(row).contents())($scope);
                })
                .withOption('headerCallback', function(header) {
                    if (!self.headerCompiled) {
                        self.headerCompiled = true;
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withDOM('<"table-responsive"lfrtip>')
                .withBootstrap()
            self.dtColumns = [
                DTColumnBuilder.newColumn('marca_nombre').withTitle('Marca').withOption('name', 'marcas.nombre').withClass('all'),
                DTColumnBuilder.newColumn('modelo_nombre').withTitle('Modelo').withOption('name', 'modelos.nombre'),
                DTColumnBuilder.newColumn('version_nombre').withTitle('Version').withOption('name', 'versiones.nombre'),
                DTColumnBuilder.newColumn('bastidor').withTitle('Bastidor').withOption('name', 'vehiculos.bastidor'),
                DTColumnBuilder.newColumn('matricula').withTitle('Matrícula').withOption('name', 'vehiculos.matricula'),
                DTColumnBuilder.newColumn('proveedor_nombre').withTitle('Proveedor').withOption('name', 'terceros.nombre'),
                DTColumnBuilder.newColumn('cliente_nombre').withTitle('Cliente').withOption('name', 'cliente.nombre'),
                DTColumnBuilder.newColumn('num_fact').withTitle('Nº Fact.').withOption('name', 'factura_transaccion.numero'),
                DTColumnBuilder.newColumn('ficha_tecnica').withTitle('Ficha T.').renderWith(function(data, type, full, meta){
                        self.vehiculos[full.vehiculo_id] = full;
                        return '<label class="switch switch-sm"><input type="checkbox" ng-model="DocumentoVehiculo.vehiculos['+full.vehiculo_id+'].ficha_tecnica" ng-change="DocumentoVehiculo.editar('+full.vehiculo_id+', \'ficha_tecnica\')"/><span></span></label>';
                }).withClass('all'),
                DTColumnBuilder.newColumn('coc').withTitle('COC').renderWith(function(data, type, full, meta){
                        return '<label class="switch switch-sm"><input type="checkbox" ng-model="DocumentoVehiculo.vehiculos['+full.vehiculo_id+'].coc" ng-change="DocumentoVehiculo.editar('+full.vehiculo_id+', \'coc\')"/><span></span></label>';
                }).withClass('all'),
                DTColumnBuilder.newColumn('copia_llave').withTitle('C. Llave').renderWith(function(data, type, full, meta){
                        return '<label class="switch switch-sm"><input type="checkbox" ng-model="DocumentoVehiculo.vehiculos['+full.vehiculo_id+'].copia_llave" ng-change="DocumentoVehiculo.editar('+full.vehiculo_id+', \'copia_llave\')"/><span></span></label>';
                }).withClass('all'),
                DTColumnBuilder.newColumn('garantia').withTitle('Grtía').renderWith(function(data, type, full, meta){
                        return '<label class="switch switch-sm"><input type="checkbox" ng-model="DocumentoVehiculo.vehiculos['+full.vehiculo_id+'].garantia" ng-change="DocumentoVehiculo.editar('+full.vehiculo_id+', \'garantia\')"/><span></span></label>';
                }).withClass('all'),
                DTColumnBuilder.newColumn('cmr').withTitle('CMR').renderWith(function(data, type, full, meta){
                        return '<label class="switch switch-sm"><input type="checkbox" ng-model="DocumentoVehiculo.vehiculos['+full.vehiculo_id+'].cmr" ng-change="DocumentoVehiculo.editar('+full.vehiculo_id+', \'cmr\')"/><span></span></label>';
                }).withClass('all'),
            ];

            self.opened1 = false;
            self.opened2 = false;
            self.open = function($event, elementOpened) {
                $event.preventDefault();
                $event.stopPropagation();
                self[elementOpened] = !self[elementOpened];
            };

            self.search = function(){
                self.dtInstance.reloadData();
            }

            self.imprimir = function(){
                DocumentoVehiculo.data_pdf({
                    to : angular.isDefined(self.to) ? self.to.toISOString() : '',
                    from : angular.isDefined(self.from) ? self.from.toISOString() : '',
                }, function(res){
                    self.documento_pdf = res;
                    self.modal('partials/documento_vehiculo/pdf', 'ModalPdfCtrl', self, 'lg');
                })
            }

            self.editar = function(index, element){
                var data = {};
                if(self.vehiculos[index].id == null){
                    data["vehiculo_id"] = index;
                    data[element] = self.vehiculos[index][element];
                    DocumentoVehiculo.save(data, function(res){
                        self.vehiculos[index].id = res.id;
                    });
                }
                else {
                    data[element] = self.vehiculos[index][element];
                    DocumentoVehiculo.update({Id: self.vehiculos[index].id}, data, function(res){
                    });
                }
            }
        }
    ]);