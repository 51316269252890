angular.module('controllers')
    .controller('EmpresaController', ['Empresa', 'editableOptions', 'validateXEditable', 'baseModelXE', '$controller', 'Pais',
        function (Empresa, EO, vxe, base, $controller, Pais) {
            angular.extend(this, $controller('LocalidaController', {child : this}));
            var self = this;
            self.vxe = vxe;
            self.paises = [];
            activate();

            function activate(){
                self.data={};
                self.data = Empresa.getDatos();
                EO.theme = 'bs3';   
            }

            self.loadPaises = function(){
                self.paises.length ? null : Pais.query(function(res){self.paises = res});
            }

            self.updateEmpresa = function(data, form){
                return base.save(Empresa, data, self.data, form).then(function(res){
                    self.data = res.empresa;
                });
            }

            self.selectedProvincia = function(item, model){
                if(angular.isDefined(self.data.poblacion)) self.data.poblacion.provincia.id = model;
                else self.data = {poblacion:{provincia:{id: model}}};
            }

            self.selectedPoblacion = function(item, model){
                self.data.poblacion.id = model;
            }
        }
    ]);